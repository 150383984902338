<template>
  <div :class="['wrapper', transparent && 'transparent', isOpen && 'is-open']">
    <div class="mobile-menu">
      <v-icon
        v-if="isOpen === false"
        @click="toggleMenu()"
        class="mobile-menu--icon"
        >$vuetify.icon.hamburgerIcon</v-icon
      >
    </div>

    <div class="logo-container">
      <v-btn to="/" class="btn-logo"
        ><v-img class="logo" src="@/assets/new/logo.svg" width="80" contain
      /></v-btn>
    </div>

    <v-spacer />

    <div class="navigation">
      <v-btn plain :ripple="false" to="/">Home</v-btn>
      <v-btn plain :ripple="false" text class="mx-1" to="/about">About</v-btn>
      <v-btn plain :ripple="false" text class="mx-1" to="/shows">Shows</v-btn>
      <v-btn plain :ripple="false" text class="mx-1" to="/tickets">TICKETS</v-btn>
      <v-btn plain :ripple="false" text class="mx-1" href="/contact">CONTACT</v-btn>

      <div class="login">
        <user-profile-menu v-if="user" />
        <v-btn plain :ripple="false" v-else text to="/auth" exact> Login </v-btn>
      </div>
    </div>

    <div v-if="isOpen === true" class="mobile-menu-list">
      <v-btn plain :ripple="false" @click="toggleMenu(false)" text class="mx-1" to="/">Home</v-btn>
      <v-btn plain :ripple="false" @click="toggleMenu(false)" text class="mx-1" to="/about"
        >About</v-btn
      >
      <v-btn plain :ripple="false" @click="toggleMenu(false)" text class="mx-1" to="/shows"
        >Shows</v-btn
      >
      <v-btn plain :ripple="false" @click="toggleMenu(false)" text class="mx-1" to="/tickets"
        >TICKETS</v-btn
      >
      <v-btn plain :ripple="false" href="/contact" text class="mx-1" 
        >CONTACT</v-btn
      >

      <div class="login">
        <user-profile-menu v-if="user" />
        <v-btn plain :ripple="false" @click="toggleMenu(false)" v-else text to="/auth" exact>
          Login
        </v-btn>
      </div>
    </div>
  </div>

  
</template>

<script>
import { mapState } from "vuex";

import UserProfileMenu from "./PageProfileMenu";

export default {
  watch: {
    $route() {
      this.toggleMenu(false);
    },
  },
  components: {
    UserProfileMenu,
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      transparent: (state) => state.ui.transparentHeader,
    }),
  },
  methods: {
    toggleMenu(state = null) {
      if (state !== null) {
        this.isOpen = state;
        return;
      }
      this.isOpen = !this.isOpen;
    },
    navigate(path) {
      this.$router.push({ path: path });
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  height: 87px;
  width: 100%;
  padding: 0.125rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  z-index: 999;

}

.wrapper.transparent {
  position: absolute;

  width: 100%;

   z-index: 999;
}

.logo {
  // border-radius: 50%;
  // border: 3px solid #2b2b2b;

  margin: 1rem;

  // width: 50px;
  // height: 50px;

  // text-align: center;
  // line-height: 45px;

  @media screen and(max-width:850px) {
    width: 58px!important;
    margin: 0;
  }
}

.btn-logo {
  @media screen and(max-width:850px) {
      min-width: 58px!important;
    }
}

@media screen and(min-width:850px) {
  .wrapper {
    padding: 40px 20px 0 40px;
  }
}
html #app .navigation {
  overflow-x: hidden;
  display: flex;
  flex-flow: row nowrap;

  justify-content: flex-end;
  align-items: center;
  a {
    letter-spacing: 5px;
    font-family: Oswald;
    font-weight: 100;
    &.v-btn--active {
      &:before {
        opacity: 0 !important;
      }
      font-weight: 700;
    }

    &::v-deep span {
      font-size: 18px;
      line-height: 27px;
      text-align: right;
      letter-spacing: 6px;
      text-transform: uppercase;
      padding: 0 11px;

      @media screen and(max-width:991px) {
        font-size: 18px;
        letter-spacing: 2px;
        padding: 0;
      }
    }
  }
}

.login {
  margin: 0 0.5rem;
  font-size: 14px;

  @media screen and(max-width:850px) {
    margin: 0;
    padding: 0 5px;
  }
}

.profile {
  text-transform: uppercase;
  font-weight: bold;
}
.mobile-menu {
  display: none;
}
@media screen and(max-width:850px) {
  .mobile-menu {
    margin-left: 1em;
    display: block;
  }
  .wrapper {
    background-color: black !important;
  }
  .logo-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
  }
  html #app .navigation {
    display: none;
  }
  .home-top--banner {
    min-height: 550px;
  }
}

.mobile-menu-list {
  position: absolute;
  background-color: black;
  top: 87px;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  box-sizing: border-box;
  z-index: 999;
  left: 0;
  a {
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
  .login {
    border-left: none;
  }
  .theme--dark.v-btn--active::before {
    opacity: 0;
  }
}

</style>
