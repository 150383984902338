<template>
<svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M3.32207 7.04922C3.47786 7.2039 3.72982 7.20316 3.8845 7.04699C4.03918 6.89119 4.03844 6.63923 3.88264 6.48455L0.960501 3.58627L3.88264 0.680167C4.03843 0.525115 4.03918 0.27353 3.8845 0.117361C3.72982 -0.038435 3.47786 -0.0391793 3.32207 0.115498L0.117783 3.29593H0.11741C0.0421207 3.37084 0 3.47223 0 3.57845C0 3.6843 0.0421179 3.78605 0.11741 3.86059L3.32207 7.04922Z" fill="white"/>
</svg>

</template>
<script>
export default {

}
</script>