/* eslint-disable no-unused-vars */
const state = {
  connected: false
}

const getters = {
  connectStatus: () => state.connected
}

const mutations = {
  streamConnected(){
    state.connected = true
  },  
  streamDisconnected(){
    state.connected = false
  }  
}

const actions = {
  async streamConnected(context) {
    context.commit("streamConnected")
  },
  async streamDisconnected(context) {
    context.commit("streamDisconnected")
  }
}

export default { namespaced: true, state, getters, mutations, actions }
/* eslint-enable no-unused-vars */