<template>
  <v-card
    class="mx-auto align-self-center  transparent-bg"
    v-bind:class="[reveal ? 'confirmation-mode' : '']"
    max-width="430"
  >     
  <div v-if="!reveal">
    <v-card-text>
      <div>
        <v-col class="log-input log-google">
           <v-btn block>
             log in with google
           </v-btn>
         </v-col>
        <!-- <h2><span>OR</span></h2> -->
        <div class="form-error">{{ notification }}</div>
        <v-col class="log-input" v-if="form.invite.code">
          <v-text-field
            label="Invite"
            v-model="form.invite.code"
            hide-details
            disabled
            dense
            outlined
            prepend-inner-icon="$vuetify.icon.qrIcon"
            height="60px"
            style="line-height: 1;"
          ></v-text-field>
        </v-col>
        <v-col class="log-input">
          <v-text-field label="Email"
                        v-model="form.email"
                        :rules="emailConfirmationRules"
                        hide-details="auto"
                        dense
                        outlined
                        prepend-inner-icon="$vuetify.icons.emailIcon"
                        height="60px"
        ></v-text-field>
        </v-col>
        <v-col class="log-input">
          <v-text-field label="Enter Password"
                        type="Password"
                        v-model="form.password"
                        :rules="rules"
                        hide-details="auto"
                        dense
                        outlined
                        prepend-inner-icon="$vuetify.icons.lockIcon"
                        height="60px"
          ></v-text-field>
        </v-col>
        <v-col class="log-input">
          <v-text-field label="Confirm Password"
                        type="Password"
                        v-model="form.confirmPassword"
                        :rules="passwordConfirmRules"
                        hide-details="auto"
                        dense
                        outlined
                        prepend-inner-icon="$vuetify.icons.rePasswordIcon"
                        height="60px"
                        @keydown.enter="validateInvite"
          ></v-text-field>
        </v-col>

      </div>
    </v-card-text>
    <v-card-actions>
      <v-btn text
             color="#FF3C11 white--text"
             @click="validateInvite"
             width="100%"
             height="60px"
             :disabled="!form.password ||  !form.confirmPassword || !form.email">
        Submit
      </v-btn>
    </v-card-actions>    
    <v-overlay :absolute="absolute"
               :value="overlay">
    </v-overlay>
    <br>
    <v-progress-linear v-if="overlay"
                       indeterminate
                       color="teal accent-4">
    </v-progress-linear>
    <v-snackbar v-model="snackbar"
                :multi-line="multiLine"
                color="teal darken-4">
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn text
               color="red"
               center
               v-bind="attrs"
               @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
    <v-expand-transition v-if="reveal">
      <v-card
              class="transition-fast-in-fast-out align-self-center v-card--reveal confirmation-popUp"
              style="height: 100%;"
      >
        <v-card-text class="pb-0">
          <header>
            <div class="title">Congrats!</div>
            <!-- <div class="sub-title">YOU HAVE BEEN REGISTERED</div> -->
            <hr class="line-separator">
          </header>
          <p class="confirmation-message">You have been registered!<br>
            Please follow instructions we send to your email to confirm registration.
          </p>
          <div>
          <!-- <v-col class="log-input">
            <v-text-field label="Code"
                          v-model="form.authCode"
                          :rules="rules"
                          outlined
                          hide-details="auto"
                          height="60px"
                          prepend-inner-icon="$vuetify.icons.keyboardIcon"
                          dense
            ></v-text-field>
          </v-col> -->
          </div>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-btn  class="orange-btn"
                 height="60px"
                 :to="'/'"
                 >
            CONTINUE TO HOMEPAGE
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
//import { Auth } from 'aws-amplify'
import awsAuth from '@/cognito/aws-auth'
import request from "@/plugins/request";
export default {
  name: 'signup',
  props: ['toggle'],
  data() {
    return {
      formState: 'signUp',
      reveal: true,
      // reveal: false,
      absolute: true,
      overlay: false,
      multiLine: true,
      snackbar: false,
      notification: '',
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
      passwordConfirmRules: [
        value => !!value || 'Required.',
        value => (value === this.form.password) || 'Password mismatch',
      ],
      emailConfirmationRules:[
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must match',
        v => !!v || 'Confirmation E-mail is required'
      ],
      form: {
        username: '',
        password: '',
        email: '',
        invite: {
          code: null,
          token: null
        },

      }
    }
  },
  mounted() {
    let query = this.$route.query;
    if (query.code) {
      this.form.invite.code = query.code;
    }
    if (query.token) {
      this.form.invite.token = new URLSearchParams(query.token).toString();
    }
  },
  methods: {
    validateInvite() {
      if (!this.form.invite.code || !this.form.invite.token) {
        this.snackbar = true;
        this.notification = 'Sign up is not available without invite. Please contact us for help.';

        return;
      }

      let url = `/profiles/api/v1/Profile/CheckInvite?code=${this.form.invite.code}&hash=${encodeURIComponent(this.form.invite.token)}`;

      request({ method: 'GET', url: url })
        .then(res => {
          if (res.data) {
            this.signUp();
          }
          else {
            this.snackbar = true;
            this.notification = `Your invitation code is invalid. Please contact us for help`;
            return;
          }
        })
        .catch(err => {
          this.snackbar = true;
          this.notification = err.message;
        });
    },
    async signUp() {
      this.overlay = true
      const { password, email } = this.form
      const username = email.substring(0, email.lastIndexOf("@"));
      this.$store.dispatch('auth/signup', { username, email, password })
        .then(()=>{
          this.formState = 'confirmSignUp'
          this.reveal = true
        })
        .catch(error=>{
          this.snackbar = true
          this.notification = `ERROR! ${error.message}`
        })
      this.overlay = false      
    },
    async confirmSignUp() {
      const { authCode, password, email } = this.form
      const username = email.substring(0, email.lastIndexOf("@"));
      let self = this
      self.overlay = true
      const url = `${window.location.origin}`
      awsAuth.confirmRegister(username, password, email, authCode, url)
        .then(()=>{
          self.snackbar = true
          self.notification = 'Successfully signed up! Use your username and password for the sign in to view the app.'
          setTimeout(self.redirect, 2300)          
        })
        .catch(error=>{
          self.snackbar = true
          self.overlay = false;
          self.notification = `ERROR! ${error.message}`
        })
    },
    redirect(){
      this.snackbar = false;
      this.toggle('signIn')
      //this.$router.push(this.$route.query.redirect || "/auth")
    }
  }
}
</script>

<style scoped  lang="scss">
header{
  text-align: center;
  margin-top:30%;
    .title{
      font-weight: 600!important;
      font-size: 44px!important;
      line-height: 1.1;
      text-align: center;
      letter-spacing: 2.93333px;
      color: #FFFFFF;
      margin: 0;
      padding: 0;
    }

    .sub-title{
      font-weight: 300!important;
      font-size: 30px!important;
      line-height: 1.5;
      text-align: center;
      letter-spacing: 2.93333px;
      color: #FFFFFF;
    }

    .separator{
        margin:auto;
        width:20%;
        max-width:50px;
        border-color: #979797;
        margin-top:10px;
 ;
    }
}

.confirmation-message{
font-weight: 400;
font-size: 18px!important;
line-height: 27px!important;
text-align: center;
letter-spacing: 2.7px;

}
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.log-input {
  padding: 5px 0px 5px 0px;
}
h2 {
   width: 100%; 
   text-align: center; 
   border-bottom: 1px solid #000; 
   line-height: 0.1em;
   margin: 10px 0 20px; 
} 
h2 span { 
    background:#fff; 
    padding:0 10px; 
}

.log-google{
  display:none;
}

.login-form {
  padding: 8px 0px;
  padding-top: 16px;
}

.log-input {
  padding: 20px 0px 0px 0px!important;
}

#app .confirmation-popUp {
  position: initial;
  background-color: transparent;
  box-shadow: none;
}

#app .confirmation-popUp p {
  font-size: 24px;
  line-height: 1;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1.4px;
  color: #FFFFFF;
}

#app .confirmation-popUp .v-card__actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

#app .confirmation-popUp .v-card__actions > button {
  width: 100%;
  margin: 0 0 10px;
}





#app .confirmation-mode + >>> .sign-options {
  display: none;
}

>>> .v-text-field__details {
  position: absolute;
  bottom: -25px;
}

>>> .v-input--dense > .v-input__control > .v-input__slot {
  margin-bottom: 0;
}

.v-card__actions {
  margin-top: 5px;
}

h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
h2 span {
  background: #fff;
  padding: 0 10px;
  color: #000;
  font-size: 0.9em;
  font-weight: 450;
}

.log-google{
  display:none
}


.v-text-field--outlined.v-input--dense .v-label {
    top: 20px;
}


>>> .v-label
{
    top: 50%!important;
    transform: translateY(-50%);
}

>>> .v-text-field .v-input__prepend-inner{
  align-self: center;
  margin-top: 0!important;
}

>>>.v-icon__component {
    height: 20px;
    width: 20px;
}

.v-btn.v-btn--has-bg {
  background-color: #FF3C11!important;
  max-width:250px;
  max-height:50px!important;
  padding:0 20px!important;

}

 .v-btn__content{
        font-size: 14px!important;
        font-weight: 700!important;
        line-height: 21px!important;
        letter-spacing: 1.5555555820465088px!important;
}



.v-card__actions > .v-btn.v-btn {
  span.v-btn__content{
        font-size: 14px!important;
        font-weight: 700!important;
        line-height: 21px!important;
        letter-spacing: 1.5555555820465088px!important;
      }
}
.form-error{
  color: #FF3C11!important;
  text-align: center;
  text-transform: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 2px;
}
>>> .v-text-field--outlined legend{
  display:none
}

>>> .v-input--is-focused label,
>>> .v-input--is-dirty label
{
  display:none
}

  @media screen and (max-width: 850px) {
    .confirmation-message{
      margin-top: 50px;
    }
    .v-card__actions > .v-btn.v-btn{
          height: 60px!important;
          width: 100%;
          max-width: 100%;
          max-height: 100px!important;
          margin-top: 40px;
    }

    .v-btn__content{
      font-weight: 700!important;
    }
  }
</style>