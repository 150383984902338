<template>
  <v-footer class="wrapper black lighten-1 white--text text-center flex-container">
    <div class="copyright">
      ©COPYRIGHT {{ new Date().getFullYear() }}. ALL RIGHTS RESERVED.
      <span class="link-block">
        <a href="https://www.instagram.com/thevitaverse/" target="_blank"><IconInstagram/></a>
        <a href="https://twitter.com/thevitaverse" target="_blank"><IconTwitter /></a>
        <a href="https://www.facebook.com/profile.php?id=100086753732023" target="_blank"><IconFacebook/></a>
        <a href="https://www.youtube.com/channel/UCUb11OkrBB38QSZhOfvIa4A" target="_blank"><IconYoutube/></a>
        <a class="discord" href="https://discord.gg/4QpFygMCNs" target="_blank"><IconDiscord /></a></span>
    </div>
    <!-- <div class="social">
      <v-btn icon>
        <v-icon color="white darken-3">fab fa-instagram </v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon color="white darken-3">fab fa-twitter </v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon color="white darken-3">fab fa-facebook</v-icon>
      </v-btn>
      <v-btn icon>
        <v-icon color="white darken-3">fab fa-youtube </v-icon>
      </v-btn>
    </div> -->
    <!-- <div class="policy">
      <div class="policy-item">CONTACT SUPPORT</div>
      <div class="separator">|</div>
      <div class="policy-item">PRIVACY POLICY</div>
      <div class="separator">|</div>
      <div class="policy-item">TERMS & CONDITIONS</div>
    </div> -->
  </v-footer>
</template>
<script>
import IconInstagram from "@/components/icons/IconInstagram.vue";
import IconTwitter from "@/components/icons/IconTwitter.vue";
import IconFacebook from "@/components/icons/IconFacebook.vue";
import IconYoutube from "@/components/icons/IconYoutube.vue";
import IconDiscord from "@/components/icons/IconDiscord.vue";

export default {
  components: {
    IconInstagram,
    IconTwitter,
    IconFacebook,
    IconYoutube,
    IconDiscord,
  },
};
</script>

<style lang="scss" scoped>
svg {
  margin-left: 15px;
}

.discord svg {
  width: 20px;
  height: 20px;
}

.wrapper {
  background-color: #1b1b1b;
  display: flex;
  // flex-flow: column nowrap;
  justify-content: center;
  height: 110px;
  width: 100%;
}

.policy {
  display: flex;
  flex-flow: row nowrap;

  justify-content: center;
  align-items: center;

  .separator {
    margin: 0.25rem;
  }
}

.policy,
.copyright {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1.8px;
  color: #ffffff;
  display: flex;
}

.copyright {
  text-align: center;
}

.social {
  display: flex;
  flex-flow: row nowrap;

  justify-content: center;
}

.social > * {
  margin: 0 0.3rem;
}

.title--underline {
  font-weight: 100;
  display: inline-block;
  border-bottom: 1px solid;
  padding-bottom: 9px;
}

@media screen and(max-width:850px) and (orientation: portrait) {
  .copyright {
    letter-spacing: 0;
    font-size: 11px;

    svg {
      margin-left: 3px !important;
    }
    span {
      margin-left: 3px !important;
      line-height: 20px;
      // display: flex;
      // vertical-align: middle;
    }
  }
}
.link-block {
  display: flex;
  align-items: center;
}
</style>
