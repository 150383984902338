<template>
<svg width="20" height="14" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_337_3351" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="14">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H20V13.9999H0V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_337_3351)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M8 10.3831V2.85111L13.7118 6.61733L8 10.3831ZM20 2.99067C20 1.33911 18.662 0 17.0113 0H2.98911C1.338 0 0 1.33911 0 2.99067V11.0093C0 12.6611 1.338 14 2.98911 14H17.0113C18.662 14 20 12.6611 20 11.0093V2.99067Z" fill="white"/>
</g>
</svg>


</template>
<script>
export default {

}
</script>