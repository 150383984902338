import axios from "axios";

const state = {
    logs: [],
    log: ''
};

const mutations = {
    addLog(state, message) {
        state.logs.push({ message, date: new Date()})
        state.log = message
    },
    clearLog(state) {
        state.log = ''
    }
}

const actions = {
    async sendLog({ state, commit}) {
    try {
        console.log(state.logs)
        await axios.post(
            'http://localhost:3000/', {
                message: state.log
            }
        )
        commit('clearLog')
    } catch (e) {
        console.log(e.message)
    }
    }
}


export default { namespaced: true, state, mutations, actions}
