/* eslint-disable no-unused-vars */
const state = {
    connected: false,
    socketMessage: ''
  }
  
  const getters = {
    socketConnectStatus: () => state.connected
  }
  
  const mutations = {
    socketConnected() {
      state.connected = true
    },  
    socketDisconnected() {
      state.connected = false
    },
    receiveMessage(message) {
      console.log('receiveMessage: '+ message)
      state.socketMessage = message    
    }  
  }
  
  const actions = {
    socketConnected(context) {
      context.commit("socketConnected")
    },
    socketDisconnected(context) {
      context.commit("socketDisconnected")
    },
    receiveMessage ({ commit }, payload) {
      commit('receiveMessage', payload)
    }
  }
  
  export default { namespaced: true, state, getters, mutations, actions };
  /* eslint-enable no-unused-vars */