import Vue from 'vue';
import Zendesk from '@dansmaculotte/vue-zendesk';

import loader from '@/config.loader'

Vue.use(Zendesk, {
  key: loader.getConfigValue('VUE_ZENDESK_KEY'),
  disabled: true,
  hideOnLoad: true,
  settings: {
    webWidget: {
      color: {
        theme: '#5B3477'
      },
      launcher: {
        badge: {
          label: {
            '*': 'Need Help?',
            fr: 'How can we help you?'
          }
        },
        chatLabel: {
          '*': 'Support'
        }
      }
    }
  }
});