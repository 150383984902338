import Vue from 'vue';
import Vuex from 'vuex';

import SignalingModule from '@/store/signaling';
import AuthModule from '@/store/auth';
import UIModule from '@/store/ui';
import SocketModule from '@/store/socket';
import LogsModule from '@/store/log';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    signaling: SignalingModule,
    auth: AuthModule,
    ui: UIModule,
    socket: SocketModule,
    log: LogsModule
  },
  strict: debug
});
