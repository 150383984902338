<template>
<svg width="12" height="17" viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.21245 4.77626H3.4692V3.29753C3.4692 2.15996 4.3786 1.19406 5.57267 1.19406H6.31148C7.16439 1.19406 7.90321 1.70581 8.24437 2.44463C8.35844 2.72929 8.69961 2.84228 8.98319 2.72929C9.26785 2.61521 9.38084 2.27404 9.26785 1.99047C8.75611 0.796407 7.61854 0 6.31148 0H5.57267C3.81036 0 2.33163 1.42114 2.33163 3.24103V4.77626C1.02348 5.00334 0 6.1974 0 7.56205V13.8735C0 15.4653 1.25056 16.7169 2.84337 16.7169H9.15486C10.7466 16.7169 11.9982 15.4663 11.9982 13.8735V7.61963C12.0547 6.02685 10.7477 4.77626 9.21245 4.77626ZM10.9183 13.9311C10.9183 14.8981 10.1794 15.6369 9.21245 15.6369H2.84337C1.87639 15.6369 1.13757 14.8981 1.13757 13.9311V7.61963C1.13757 6.65265 1.87639 5.91383 2.84337 5.91383H9.15486C10.1218 5.91383 10.8607 6.65265 10.8607 7.61963V13.9311H10.9183Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.40633 8.05497C8.25644 8.06082 8.1142 8.12909 8.01023 8.24611C6.90654 9.44418 6.06387 10.4447 5.03947 11.5775L3.94837 10.5789C3.83179 10.4721 3.6801 10.42 3.52751 10.4336C3.37492 10.4477 3.23403 10.5272 3.13546 10.654C3.03689 10.7813 2.98873 10.9451 3.00223 11.1104C3.01574 11.2757 3.08911 11.4283 3.20658 11.5346L4.70458 12.9077C4.93325 13.1164 5.27084 13.1013 5.48239 12.8726C6.72342 11.5254 7.60701 10.4492 8.82408 9.12776C8.99513 8.9488 9.04734 8.67428 8.95461 8.4368C8.86234 8.19886 8.64448 8.04769 8.40637 8.055L8.40633 8.05497Z" fill="white"/>
</svg>



</template>
<script>
export default {

}
</script>