<template>
  <v-container fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10" md="8">
        <div class="privacy-header mb-0">VITAVERSE INC.</div>
        <div class="privacy-header mt-0">PRIVACY POLICY</div>
        <span class="caption">Last modified: May 2023</span>
        <div class="privacy-text mt-4">
          <p>
            VitaVerse Inc. (<b>"Company"</b>, <b>"We"</b>, or <b>"Our"</b>) respect your privacy and are committed to protecting it. We take your privacy as seriously as you do, and we are committed to protecting it.
          </p>
          <p>
            We created this Privacy Policy to give you confidence as you use our website and mobile application and demonstrate our commitment to fair information practices and privacy protection. This Privacy Policy describes the types of information we may collect from you or that you may provide when you visit the website www.vitaverse.io or use our mobile application (collectively as our “Products”) and our practices regarding the collection, use, disclosure, transfer, storage, and protection of Personal Information (as defined below) that is collected through our Products and services, as well as your choices regarding the same.
          </p>
          <p>
            Please read this Privacy Policy carefully to understand our policies and practices regarding your information and how we will treat it. If you do not agree with our policies and practices, your choice is not to use our website and/or our mobile application. By accessing or using our website and/or our mobile application, you agree to this Privacy Policy. This Privacy Policy may change from time to time (see Changes to our Privacy Policy). Your continued use of our website and/or our mobile application after we make changes, are deemed to be acceptance of those changes, so it is your duty to check the policy periodically for updates.
          </p>
          <p>
            <u>Children Under the Age of 13</u>
          </p>
          <p>
            VitaVerse is not intended for children under 13 years of age. No one under age 13 may provide any information, including but not limited to Personal Information on our website or on our mobile application. We do not knowingly collect Personal Information from children under 13. If you are under 13, do not use or provide any information on our website or our mobile application through any of its features, register on our website or our mobile application, make any purchases through this website or our mobile application, use any of the interactive features of our website or our mobile application, or provide any information about yourself to us, including your name, address, telephone number, email address, or any screen name or user name you may use. If we learn we have collected or received Personal Information from a child under 13 without verification of parental consent, we will take appropriate steps to delete that information. If you are a parent or guardian who believes that we might have any information from or about a child under 13, please contact us at <a href="mailto:support@vitaverse.io">support@vitaverse.io</a>.
          </p>
          <p>
            <u><b>Information We Collect and How We Collect It</b></u>
          </p>
          <p>
            We collect information from and about users of our Products directly from you when you provide it to us or automatically when you use our Products.
          </p>
          <p>
            <u><i>Information You Provide to Us</i></u>
          </p>
          <p>
            When you download, register with, or use our Products, we may ask you to provide information:
          </p>
          <ul>
            <li>
              By which you may be personally identified, such as name, postal address, email address, telephone number, or any other identifier by which you may be contacted online or offline (<b>“Personal Information”</b>).
            </li>
            <li>
              That is about you but individually does not identify you.
            </li>

          </ul>
          <p>
            This information includes:
          </p>
          <ul>
            <li>
              Information that you provide by filling in forms in our website and/or our mobile application. This includes information provided at the time of registering to use our website and/or mobile application, subscribing to our services, and requesting further services. We may also ask you for information when you report a problem with our Products.
            </li>
            <li>
              Records and copies of your correspondence (including email addresses and phone numbers) if you contact us.
            </li>
            <li>
              Details of transactions you carry out through our website or mobile application and of the fulfillment of your orders. You may be required to provide financial information before placing an order through our website or mobile application.
            </li>
          </ul>
          <br>
          <p>
            <u><i>Automatic Information Collection</i></u>
          </p>
          <p>
            When you download, access, and use our Products, we may use automatic data collection technologies to automatically collect certain details, including how frequently you access our website and/or our mobile application, the features you use, the links you click, purchase transactions, purchase transaction information, your location when you access or interact with our website and mobile application, related technical information about your computer or device and operating and/or network systems (such as IP Address and device ID), and other communication data and the resources that you access and use on or through our Products.
            If you do not want us to collect this information, do not use our Products or delete it from your device.<br>
            Where you have consented to VitaVerse Privacy Policy, including processing your information, you may withdraw that consent at any time and opt out of certain processing activities, such as targeted marketing communications (see Right to Opt Out). Even if you opt out, we may still collect and use information regarding your activities on our websites and/or information from the advertisements on third-party websites for non-interest-based advertising purposes, such as to determine the effectiveness of the advertisements.
          </p>
          <p>
            <u><i>Information Collection and Tracking Technologies</i></u>
          </p>
          <p>
            VitaVerse utilizes a standard technology called “cookies” and server logs to collect information about how our Products are used. Information gathered through cookies and server logs may include the date and time of visits, the pages viewed, time spent on the VitaVerse website or mobile application, and the websites visited just before and just after our own, as well as your IP address.<br>
            A cookie is a very small text document, which often includes an anonymous unique identifier placed on your smartphone. When you visit a website, that site’s computer asks your computer for permission to store this file in a part of your hard drive specifically designated for cookies. Each website can send its own cookie to your browser if your browser’s preferences allow it, but (to protect your privacy) your browser only permits a website to access the cookies it has already sent to you, not the cookies sent to you by other sites. It may be possible to refuse to accept mobile cookies by activating the appropriate setting on your computer or smartphone. However, if you select this setting, you may be unable to access certain parts of our Products.<br>
            Pages of our website and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit VitaVerse, for example, to count users who have visited those pages for other related Product statistics, for example, recording the popularity of certain website or mobile application content and verifying system and server integrity.
          </p>
          <p>
            <u><i>Third-Party Information Collection</i></u>
          </p>
          <span>
            When you use our Products or its content, certain third parties may use automatic information collection technologies to collect information about you or your device. These third parties may include:
          </span>
          <ul>
            <li>
              Advertisers, ad networks, and ad servers.
            </li>
            <li>
              Analytics companies.
            </li>
            <li>
              Your mobile device manufacturer.
            </li>
            <li>
              Your mobile service provider.
            </li>
          </ul>
          <p>
            These third parties may use tracking technologies to collect information about you when you use our Products. The information they collect may be associated with your Personal Information, or they may collect information, including Personal Information, about your online activities over time and across different websites, apps, and other online services websites. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.
            We do not control these third parties tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly.
          </p>
          <p>
            <u><b>How We Use Your Information</b></u>
          </p>
          <span>
            We use information that we collect about you or that you provide to us, including any Personal Information, to:
          </span>
          <ul>
            <li>Provide you with our services within our Products and its contents and any other information, products, or services that you request from us.</li>
            <li>Fulfill any other purpose for which you provide it.</li>
            <li>Give you notices about your account/subscription, including expiration and renewal notices.</li>
            <li>Carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.</li>
            <li>Notify you when our Product updates are available and of changes to any products or services we offer or provide through it.</li>
          </ul>
          <br>
          <span>
            The usage information we collect helps us to improve our Products and to deliver a better and more personalized experience by enabling us to:
          </span>
          <ul>
            <li>Estimate our audience size and usage patterns.</li>
            <li>Store information about your preferences, allowing us to customize our Products according to your individual interests.</li>
            <li>Speed up your searches.</li>
            <li>Recognize you when you use our Products.</li>
          </ul>
          <br>
          <p>
            <u><b>Disclosure of Your Information</b></u>
          </p>
          <span>As a general rule, we do not sell, rent, lease or otherwise transfer any information collected, whether automatically or through your voluntary action. We may disclose Personal Information that we collect, or you provide:</span>
          <ul>
            <li>To our subsidiaries and affiliates.</li>
            <li>To contractors, service providers, and other third parties we use to support our business and who are bound by contractual obligations to keep Personal Information confidential and use it only for the purposes for which we disclose it to them.</li>
            <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of VitaVerse's assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Information held by VitaVerse about our Product users is among the assets transferred.</li>
            <li>For any other purpose disclosed by us when you provide the information.</li>
            <li>To comply with any court order, law, or legal process, including responding to any government or regulatory request.</li>
            <li>To enforce our rights arising from any contracts entered into between you and us and for billing and collection.</li>
            <li>If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of VitaVerse, our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.</li>
          </ul>
          <br>
          <p>
            <u><b>Your Choices About Our Collection, Use, and Disclosure of Your Information</b></u>
          </p>
          <span>We strive to provide you with choices regarding the Personal Information you provide to us. This section describes mechanisms we provide for you to control certain uses and disclosures of your information.</span>

          <ul>
            <li><b>Tracking Technologies.</b> You can set your browser to refuse all or some browser cookies or to alert you when cookies are being sent. If you disable or refuse cookies or block the use of other tracking technologies, some parts of our Products may then be inaccessible or not function properly.</li>
            <li><b>Location Information.</b> You can choose whether or not to allow our Products to collect and use real-time information about your device's location. If you block the use of location information, some parts of our Products may become inaccessible or not function properly.</li>
            <li><b>Promotion by the Company.</b> If you do not want us to use your contact information to promote our own or third parties’ products or services, you can opt out by sending us an email at <a href="mailto:support@vitaverse.io">support@vitaverse.io</a>.</li>
          </ul>
          <br>
          <p>
            <u><b>Accessing and Correcting Your Personal Information</b></u>
          </p>
          <p>
            You can review and change your Personal Information by logging into our website or mobile application and visiting your account profile page. You may also send us an email at <a href="mailto:support@vitaverse.io">support@vitaverse.io</a> to request access to, correct, or delete any Personal Information that you have provided to us. We cannot delete your Personal Information except by also deleting your user account.
          </p>
          <p>
            <u>Your California Privacy Rights</u>
          </p>
          <p>
            California residents can make certain requests about their Personal Information under the California Consumer Privacy Act (CCPA). Specifically, if you are a California resident, you have the right to request any or all of the following information relating to your Personal Information we have collected and used in the last 12 months upon verification of your identity:
          </p>
          <ul>
            <li>Provide you with the specific pieces of Personal Information we have collected about you and information about the categories of Personal Information we have collected about you; the categories of sources of the Personal Information; the categories of Personal Information that we have disclosed to third parties for a business purpose, and the categories of recipients to whom this information was disclosed; and the categories of Personal Information we have shared and the categories of third parties to whom the information was shared.</li>
          </ul>
          <br>
          <p>
            You also have the following rights with regard to your Personal Information such as:
          </p>
          <ul>
            <li>the right to opt out of Personal Information shares for cross-context behavioral advertising;</li>
            <li>the right to limit our use of your sensitive Personal Information;</li>
            <li>the right to correct inaccurate Personal Information about you;</li>
            <li>the right to delete certain information we have about you;</li>
            <li>the right for us to provide you with information about the financial incentives that we offer to you, if any; and</li>
            <li>the right to not receive discriminatory treatment for exercising these rights.</li>
          </ul>
          <br>
          <p>
            Certain information may be exempt from such requests under applicable law. For example, we may retain certain information for legal compliance and to secure our website. We may need certain information in order to provide our Products to you; if you ask us to delete it, you will no longer be able to use our Products.
          </p>
          <p>
            <u>Other Ways to Exercise Your California Rights</u>
          </p>
          <p>
            Under California’s “Shine the Light” law, California residents who provide Personal Information in obtaining products or services for personal, family, or household use are entitled to request and obtain from us, once each calendar year, information about the customer information we shared, if any, with other businesses for their own direct marketing uses. If applicable, this information would include the categories of customer information and the names and addresses of those businesses with which we shared customer information for the immediately preceding calendar year.
          </p>
          <span>
            To obtain this information, please send an e-mail message to <a href="mailto:support@vitaverse.io?subject=Request%20for%20California%20Privacy%20Information&body=Request%20for%20California%20Privacy%20Information">support@vitaverse.io</a> with “Request for California Privacy Information” in the subject line and in the body of your message. We will provide in response the requested information to you at your e-mail address.
          </span>
          <br>
          <span>
            You may submit a verifiable request for information regarding the
          </span>
          <ol>
            <li>categories of Personal Information we collect, use, or share;</li>
            <li>purposes for which categories of Personal Information are collected or used by us;</li>
            <li>categories of sources from which we collect Personal Information; and</li>
            <li>specific pieces of Personal Information we have collected about you.</li>
          </ol>
          <p>
            We will not discriminate against you if you exercise your privacy rights.
            You may submit a verifiable request to close your account, and we will delete the Personal Information about you that we have collected.
          </p>

          <p>
            <u>Your Nevada Privacy Rights</u>
          </p>
          <p>
            Nevada’s Online Privacy Law, Nev. Rev. Stat. 603A (as amended) provided consumers with a right to opt out of the sale of their Personal Information. To exercise your Nevada opt-out rights: you may click
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSekJy0CVVr9NrC_QhLBHSOdukkQsaq8EhmhxqhNjJYDCnEyTw/viewform?usp=sf_link" target="_blank">here to complete this form</a> or submit your request to us by writing to us at <a href="mailto:support@vitaverse.io?subject=Nevada%20Privacy%20Rights">support@vitaverse.io</a>, with the subject line “Nevada Privacy Rights” and include your account name and mobile telephone number. We may need to contact you to request additional information to verify your request.
          </p>
          <p>
            <u>Where We Store Your Personal Information</u>
          </p>
          <p>
            As we are located in the United States, you agree that all information collected via or by VitaVerse may be transferred, processed, and stored anywhere in the world, including but not limited to the United States, the European Union, in the cloud, on our servers, on the servers of our affiliates or the servers of our service providers. Your information may be accessible to law enforcement or other authorities pursuant to a lawful request. By providing information to VitaVerse, you explicitly consent to the storage of your information in these locations if you are in Korea, the EEA, Switzerland, or the UK, this may mean that your Personal Information will be stored in a jurisdiction that offers a level of protection that may, in certain instances, be less protective of your Personal Information than the jurisdiction you are typically resident in. We will implement appropriate safeguards to ensure that your Personal Information is treated securely and in accordance with applicable Korean, EEA, and UK privacy laws and this privacy policy, including: (i) ensuring that we use your Personal Information in accordance with standard data protection clauses adopted by the European Commission for transfers of personal data to recipients outside the EEA and UK, and (ii) ensuring that any recipients of your Personal Information commit to the same standards. If you wish to enquire further about the safeguards used, please contact us at <a href="mailto:support@vitaverse.io">support@vitaverse.io</a> using the details set out at the end of this privacy notice.
          </p>
          <p>
            <u>Australian Privacy Act 1988 (Cth)</u>
          </p>
          <p>
            Except in some cases where we may rely on an exception under the Australian Privacy Act 1988 (Cth), we will take reasonable steps to ensure that such overseas recipients do not breach the Australian Privacy Principles in the Privacy Act 1988 (Cth) in relation to such information.
          </p>
          <p>
            <u>European Union Rights</u>
          </p>
          <span>
            If you are within the European Union, you are entitled to certain information and have certain rights under the General Data Protection Regulation. Those rights include:
          </span>
          <br>
          <span>
            We will retain any information you choose to provide to us until the earlier of
          </span>
          <ol>
            <li>you ask us to delete the information,</li>
            <li>our decision to cease using our existing data providers, or</li>
            <li>VitaVerse decides that the value of retaining the information is outweighed by the costs of retaining it.</li>
          </ol>
          <span>
            You have the right to request copies of your Personal Information.<br>
            You have the right to either rectify or erase your Personal Information.<br>
            You have the right to seek correction of any information you believe is inaccurate.<br>
            You have the right to request to complete information you believe is incomplete.<br>
            You have the right to seek restrictions on the processing of your Personal Information.<br>
            You have the right to object to the processing of your Personal Information and the right to the portability of your Personal Information.<br>
            To the extent that you provided consent to us processing your Personal Information, you have the right to withdraw that consent at any time without affecting the lawfulness of processing based upon consent that occurred prior to your withdrawal of consent.<br>
            You have the right to lodge a complaint with a supervisory authority that has jurisdiction over issues related to the General Data Protection Regulation.<br>
          </span>
          <br>
          <p>
            <u>Right to Opt Out</u>
          </p>
          <p>
            You have the right to withdraw your consent and opt out at any time (the “Right to Opt Out”) of certain processing activities on our Products. To exercise the Right to Opt Out, you (or your authorized representative) may click
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSekJy0CVVr9NrC_QhLBHSOdukkQsaq8EhmhxqhNjJYDCnEyTw/viewform?usp=sf_link" target="_blank">here to complete this form</a> or submit your request to us by writing to us at <a href="mailto:support@vitaverse.io?subject=Right%20to%20Opt%20Out">support@vitaverse.io</a>, with the subject line “Right to Opt Out.”
          </p>
          <p>
            <u>Changes to Our Privacy Policy</u>
          </p>
          <p>
            We may update our Privacy Policy from time to time. If we make material changes to how we treat our users' Personal Information, we will post the new Privacy Policy on this page with a notice that the Privacy Policy has been updated and a mobile application alert the first time you use the mobile application after we make the change.
          </p>
          <p>
            The date the Privacy Policy was last revised is identified at the top of the page. You are responsible for periodically visiting this Privacy Policy to check for any changes.
          </p>
          <p>
            <u><b>Contact Information</b></u>
          </p>
          <span>
            To ask questions or comment about this Privacy Policy and our privacy practices, contact us at:
          </span>
          <div style="width: 200px" class="ml-14">
            <span>VitaVerse Inc.<br></span>
            <span>765 Silver Tip Dr.<br></span>
            <span>Big Bear Lake, CA 92315<br></span>
            <span class="pl-12">or<br></span>
            <span><a href="mailto:support@vitaverse.io">support@vitaverse.io</a></span>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>

</template>

<script>
export default {
  name: "PrivacyPolicy"
}
</script>

<style scoped>

.privacy-header {
  font-size: 1.5rem;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 2rem;
  font-weight: 500;
}

.privacy-text {
  text-align: justify;
  justify-content: center;
}
</style>
