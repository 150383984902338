<template>
  <article  class="landing-page">
    <main>
      <div class="landing-image--container">
        <div style="padding:56.25% 0 0 0;position:relative;">
          <iframe   src="https://player.vimeo.com/video/761952102?autoplay=1&amp;muted=1&amp;h=bd48fadc9d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture"  allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;">
          </iframe>
        </div>
      </div>
      <div class="landing-text--container  container-1">
        <div class="welcome">WELCOME TO</div>
        <v-img
          :src="require('@/assets/login-logo.svg')"
          class="landing-logo"
          width="100%"
          contain
        />
        <div class="landing-title">THE NEXT GENERATION OF DIGITAL HOME ENTERTAINMENT!</div>
      </div>

      <div class="landing-desktop">
        <info-section
          :image="landing1.image"
          class="landing1 info-section"
          :title="landing1.title"
          :description="landing1.description"
          id="landing1"
        />
        <info-section
          reversed
          class="landing2--show info-section"
          :header="landing2.header"
          :image="landing2.image"
          :title="landing2.title"
          :subtitle="landing2.subtitle"
          :description="landing2.description"
        />
      </div>

      <div class="landing-mobile">
        <div class="landing-text--container container-2">
          <v-img
            :src="require('@/assets/new/landing1.png')"
            class="landing-logo"
            width="100%"
            contain
          />
        </div>
        <div class="landing-text--container container-3">
          <div class="welcome">REVOLUTIONARY</div>
          <div class="landing-text">Our revolutionary platform uses patent pending technology to transport you inside virtual worlds as your true self via mobile app. Built inside the 360° game engine Unreal, VitaVerse currently manifests as an interactive live music venue where concert goers can view live streams, chat, dance, and explore together as authentic versions of themselves.</div>
          <div class="landing-text">With a low barrier to entry into music and entertainment metaverse experiences, VitaVerse offers a new hybrid means to broadcast live events</div>
        </div>
        <div class="landing-text--container">
          <v-img
            :src="require('@/assets/new/landing2.svg')"
            class="landing-logo"
            width="100%"
            contain
          />
        </div>
        <div class="landing-text--container container-3">
          <div class="welcome">VITA MOTUS</div>
          <div class="landing-text">VitaVerse comes from the team at <a href="https://vitamotus.com/" class="red-text">Vita Motus</a>, an innovative, multi-disciplinary production design firm known for its work creating- ground-breaking concepts, high-tech productions, and inspirational sculptural installations for today’s most influential artists, festivals, and brands.</div>
        </div>
      </div>

      <div class="landing-text--container container-4">
        <div class="welcome">Stay tuned for official VitaVerse news and updates</div>
        <div class="landing-text">Connect with VitaVerse</div>
        <div class="login-icons">
          <a href="https://www.instagram.com/thevitaverse/" target="_blank"><IconInstagram /></a>
            <a href="https://mobile.twitter.com/thevitaverse" target="_blank"><IconTwitter /></a>
            <a href="https://www.facebook.com/profile.php?id=100086753732023" target="_blank"><IconFacebook /></a>
            <a href="https://www.youtube.com/channel/UCUb11OkrBB38QSZhOfvIa4A" target="_blank"><IconYoutube /></a>
            <a href=" https://discord.gg/4QpFygMCNs" target="_blank"><IconDiscord /></a>
            <a href="mailto:info@vitaverse.io"><IconEmailFill /></a>
        </div>
        <div class="landing-form" v-if="!joined">
          <div class="form-title">Join our email list</div>

          <v-text-field
            :value="subscribeEmail"
            :rules="emailRules"
            ref="email"
            maxlength="70"
            label="email address"
            type="email"
            :loading="isLoading"
            @input="emailInput"
            @keyup.enter="subscribe"
          >
            <template v-slot:append>
              <img style="width: auto; cursor: pointer;" src="@/assets/new/red-arrow.svg" @click="subscribe">
            </template>
          </v-text-field>
        </div>
        <div class="landing-form" v-else>
          <div class="form-title">Thanks for subscribing!</div>
        </div>
      </div>
      <v-alert
        v-model="alert"
        :color="alertColor"
        dark
        :type="alertType"
        class="alert-notify"
      >
        <div>
          {{ alertText }}
        </div>
        <template v-slot:close v-if="alertDismissible">
          <v-icon @click="alert = false" style="font-size: 18px;margin-left: 15px;">mdi-close</v-icon>
        </template>
      </v-alert>
    </main>

  </article>
</template>

<script>
import InfoSection from "./InfoSection.vue";
import IconInstagram from "@/components/icons/IconInstagram.vue";
import IconTwitter from "@/components/icons/IconTwitter.vue";
import IconFacebook from "@/components/icons/IconFacebook.vue";
import IconYoutube from "@/components/icons/IconYoutube.vue";
import IconEmailFill from "@/components/icons/IconEmailFill.vue";
import IconDiscord from "@/components/icons/IconDiscord.vue";
const image1 = require("@/assets/new/landing1.png");
const image2 = require("@/assets/new/landing2.svg");
import request from "@/plugins/request";

export default {
  name: "Landing",
  components: {
    IconInstagram,
    IconTwitter,
    IconFacebook,
    IconYoutube,
    IconEmailFill,
    IconDiscord,
    InfoSection
  },
  data() {
    return {
      joined: false,
      isLoading: false,
      emailRules:[
        v => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(v) || 'Please enter valid email',
        v => !!v || 'Email is required',
      ],
      landing1: {
        image: image1,
        title: `<span class="title--orange">REVOLUTIONARY</span>`,
        description: `Our revolutionary platform uses patent pending technology to transport you inside virtual worlds as your true self via mobile app. Built inside the 360° game engine Unreal, VitaVerse currently manifests as an interactive live music venue where concert goers can view live streams, chat, dance, and explore together as authentic versions of themselves.
                            <br><br>
                            With a low barrier to entry into music and entertainment metaverse experiences, VitaVerse offers a new hybrid means to broadcast live events
`,
      },
      landing2: {
        title: `<span class="title--orange">VITA MOTUS</span>`,
        image: image2,
        description: `VitaVerse comes from the team at <a href="https://vitamotus.com/" class="red-text">Vita Motus</a>, an innovative, multi-disciplinary production design firm known for its work creating- ground-breaking concepts, high-tech productions, and inspirational sculptural installations for today’s most influential artists, festivals, and brands. `,
      },
      videoHidden: true,
      reserved: false,
      started: false,
      subscribeEmail: '',
      alert: false,
      alertType: 'success',
      alertText: '',
      alertDismissible: false,
      alertTimeout: null
    };
  },
  computed: {
    alertColor() {
      let color = 'green';
      switch (this.alertType) {
        case 'success':
          color = 'green';
          break;
        case 'error':
          color = 'red';
          break;
        default:
          color = 'green';
      }

      return color;
    },
  },
  methods: {
    showAlert(text, type) {
      if (this.alertTimeout) clearTimeout(this.alertTimeout);

      this.alertDismissible = type !== 'success';
      this.alertType = type;
      this.alertText = text;
      this.alert = true;
      if (type == 'success') {
        this.alertTimeout = setTimeout(() => {
          this.alert = false;
        }, 3000);
      }
    },

    emailInput(val) {
      this.subscribeEmail = val.replaceAll(' ', '');
    },

    async subscribe() {
      if (!/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(this.subscribeEmail) ||
          /\s/.test(this.subscribeEmail)) {
        this.showAlert('Please enter valid email', 'error');
        return;
      }

      this.isLoading = true;

      let body = {
        email: this.subscribeEmail
      };
      try {
        let response = await request({
          method: 'POST',
          url: '/profiles/api/v1/Profile/SubscribeEmail',
          body: JSON.stringify(body)
        });

        if (response?.data?.result == 'success') {
          this.isLoading = false;
          this.showAlert('Thanks for subscribing!', 'success');
          this.joined = true;
        }
        else {
          this.showAlert( response?.data?.msg || "Something went wrong. Please try again later.", 'error');
          this.isLoading = false;
        }
      } catch (err) {
        console.log('request err - ', err);
        this.showAlert('Something went wrong. Please try again later.', 'error');
        this.isLoading = false;
      }
    },
  },

}
</script>
<style>
.alert-notify {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  margin: 0 auto;
  z-index: 1000;
}

</style>

<style lang="scss" scoped>
.landing-mobile{
  display: none;
  max-width: 80%;
    margin: auto;
}

::v-deep .landing1{
  .title{
    font-weight: 700;
    font-style: normal;
    color: rgb(255, 60, 17);
    text-decoration: none;
  }
  hr{
    display:none;
  }
}

::v-deep .landing2--show{
  hr{
    display:none;
  }
    .title{
    font-weight: 700;
    font-style: normal;
    color: rgb(255, 60, 17);
    text-decoration: none;
  }
  .red-text{
        font-weight: 400;
    font-style: normal;
    color: rgb(255, 60, 17);
    text-decoration: none;
  }
}


.red-text{
  color: #ec542d;
  text-decoration: none;
}
.landing-page {
  display: grid;

  place-content: center;

  min-width: 100%;
  min-height: 100%;

  text-align: center;

  font-size: 2rem;
  overflow-x: hidden;
  margin-top: 20px;
}

.welcome{
    color: #ec542d;
    padding-top: 100px;
    font-size: 35px;
    letter-spacing: 10px;
    font-weight: 700;
}

.landing-logo{
    max-width: 296px;
    width: 100%;
    margin: auto;
    min-width: 500px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.landing-title{
  font-size:25px;
}
.landing-text{
  font-size: 18px;
  font-weight: 400;
  margin-top:20px;
}

.bottom-text{
    max-width: 650px;
    margin: auto;
    padding-bottom:200px;
}

.landing-text--container{
  max-width: 800px;
  margin:auto;
  padding-bottom:40px;
  &.container-1{
        padding-bottom: 90px;
  }
  &.container-3{
    .welcome{
        font-size: 60px;
        letter-spacing: 3px;
        padding-top: 20px;
    }
    .landing-text{
      font-size: 25px;
    }

  }
  &.container-4{
    .welcome{
      text-transform: uppercase;
      font-weight: 900;
    }
    .landing-text{
      font-size: 25px;
      text-transform: uppercase;
      letter-spacing: 15px;
      font-weight: 900;
      margin-top: 40px;

    }
  }
}

.login-icons {
  justify-content: space-between;
  max-width: 700px;
  width: 90%;
  margin: auto;
  padding-top: 70px;
  display: flex;
  padding-bottom: 80px;
   @media screen and (max-width: 550px) {
       width: 75%;
    }

  svg {
    height: 50px;
    width: auto;
    @media screen and (max-width: 850px) {
      height: 40px;
    }
    @media screen and (max-width: 600px) {
      height: 35px;
    }
    @media screen and (max-width: 550px) {
      height: 30px;
    }
    @media screen and (max-width: 500px) {
      height: 25px;
    }
    @media screen and (max-width: 450px) {
      height: 25px;
    }
    @media screen and (max-width: 400px) {
      height: 23px;
    }
  }
}

.landing-image--container {
  .landing-video {
    position: relative;
    img {
      width: 100% !important;
    }
  }
}

.play-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  width: 75px;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
}

.landing-form {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
  max-width: 650px;
  width: 90%;
}

.form-error {
  font-size: 16px;
  color: rgb(255, 60, 17);
}

.form-title {
  font-size: 30px;
  text-transform: uppercase;
  letter-spacing: 15px;
  font-weight: 900;
  margin-top: 40px;
  padding-bottom: 70px;
}

@media screen and (max-width: 850px) and (orientation: portrait) {
  .landing-mobile {
    display: block;
  }
  .landing-desktop {
    display: none;
  }
  .section-row {
    display: block;
  }
  .landing-page {
    margin-top: 0px;
  }
  .landing-logo {
    width: 70% !important;
    max-width: 80% !important;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
    min-width: 100px;
  }
  .landing-text--container {
    max-width: 85%;
  }
}

@media screen and (max-width: 600px) {
  .landing-text--container {
    max-width: 80%;
    &.container-4 {
      max-width: 70%;
    }
    &.container-1 {
      max-width: 50%;
    }
  }
}

@media screen and (max-width: 500px) {
  .landing-text--container {
    max-width: 60%;
    &.container-4 {
      max-width: 60%;
    }
    &.container-1 {
      max-width: 60%;
    }
    &.container-3 {
      .welcome {
        font-size: 45px;
      }
    }
  }
}
@media screen and (max-width: 450px) {
  .landing-text--container {
    max-width: 45%;
    &.container-4 {
      max-width: 45%;
    }

    &.container-1 {
      max-width: 45%;
    }

    &.container-3 {
      .welcome {
        font-size: 45px;
      }
    }
  }
}
</style>
