<template>
  <article :class="[reversed && 'reversed']">
    <header v-if="header" v-html="header"></header>

    <div class="wrapepr"> 
      <slot />
    </div>

  </article>
</template>

<script>
export default {
  props: {
    header: { type: String },
    reversed: { type: Boolean, default: false }
  }
}
</script>

<style lang="scss" scoped>
.subtitle{
    font-weight: 100;
    font-size: 1.3em;
}
image{
  border-radius: 10px;;
}
.wrapper {
  width: 80%;
  margin: auto;
  max-width:100em;
}

article {
  width: 100%;
  padding: 2rem 1rem; 
}


header {
  font-size: 26px;
  font-weight: bold;

  text-align: center;
  text-transform: uppercase;
}
</style>