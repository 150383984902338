import request from "@/plugins/request";

let AmazonCognitoIdentity = require('amazon-cognito-identity-js');
let CognitoRefreshToken = require('amazon-cognito-identity-js').CognitoRefreshToken;
let authenticated = false;

/* eslint-disable no-unused-vars */
import loader from '@/config.loader'
import decoder from 'jwt-decode'
import sendMessage from '@/services/email'
import dynamicLink from '@/plugins/dynamicLink'
import agoraRequest from "@/services/agora-request";

let AWS = require('aws-sdk')

let poolData = {
	UserPoolId: loader.getConfigValue('VUE_COGNITO_USER_POOL_ID'),//'ca-central-1_9YYvbuWst', // Your user pool id here
	ClientId: loader.getConfigValue('VUE_COGNITO_CLIENT_ID') // Your client id here
}
let userPool =  new AmazonCognitoIdentity.CognitoUserPool(poolData);

export default {
  signUp(username, password, email){
    return new Promise((resolve, reject) => {
      let attributeList = [];
      let dataEmail = {
          Name: 'email',
          Value: email,
      };
      let attributeEmail = new AmazonCognitoIdentity.CognitoUserAttribute(dataEmail);
      
      attributeList.push(attributeEmail);
      
      userPool.signUp(username.replace(/\s+/g, ''), password.replace(/\s+/g, ''), attributeList, null, function(err, result) {
        if (err) {
          reject({ status: err.code, message: err.message });
        } else {
          let cognitoUser = result.user;
          resolve({ status: result.statusCode, data: cognitoUser.getUsername() });
        }
      })
    })    
  },
  confirmRegister(username, password, email, confirmCode, url){
    return new Promise((resolve, reject) => {
      let userData = {
        Username: username.replace(/\s+/g, ''),
        Pool: userPool,
      };  
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.confirmRegistration(confirmCode.replace(/\s+/g, ''), true, function(err, result) {
        if (err) {
          reject({ status: err.code, message: err.message });
        } else {
          sendMessage({toEmail: email, username: email, password: password, url: url})
          resolve({ status: result.statusCode, data: result });
        }
      });
    });    
  },  
  signIn(username, password){
    return new Promise((resolve, reject) => {
      let authenticationData = {
        Username : username.replace(/\s+/g, ''),
        Password : password.replace(/\s+/g, ''),
      };
      let authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      let userData = {
          Username : username.replace(/\s+/g, ''),
          Pool : userPool
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: function (result) {            
            cognitoUser.getSession(function(err, session) {
              if (err) {
                reject({ status: err.statusCode, message: err.message });
              }
              let accessToken = result.getIdToken().getJwtToken();
              let decodeToken = decoder(accessToken);
              authenticated = true;
              dynamicLink.createDynamicLink(accessToken)
                .then(result => {
                  localStorage.setItem('dynamic-link', result.data.shortLink);
                });
              
              localStorage.setItem('cognito-user-token', accessToken);
              localStorage.setItem('cognito-user-id', decodeToken.sub);
              localStorage.setItem('cognito-user-info', username);
              localStorage.setItem('session-token', session.getRefreshToken().token);
              
              resolve({ status: result.statusCode, data: accessToken });
            })
          },
          onFailure: function(err) {
            reject({ status: err.statusCode, message: err.message });
          }
      });
      /*if(username == 'admin' && password=='admin'){
        localStorage.setItem('cognito-user-token', 'sometoken');
        localStorage.setItem('cognito-user-info', username);
        resolve({ status: 200, data: 'sometoken' });
      } else reject({ status: 400, message: 'ERROR' });*/
    });
  },
  restorePassword(username){
    return new Promise((resolve, reject) => {
      let userData = {
        Username: username.replace(/\s+/g, ''),
        Pool: userPool
      }
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: function(data) {
          // successfully initiated reset password request
          resolve({ status: 200, data: `email: ${data.CodeDeliveryDetails.Destination}` });
        },
        onFailure: function(err) {
          reject({ status: err.code, message: err.message });
        }
      });
    })
  },
  confirmPassword(email, username, url, confirmCode, newPassword){
    return new Promise((resolve, reject) => {
      let userData = {
        Username: username.replace(/\s+/g, ''),
        Pool: userPool,
      };  
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.confirmPassword(confirmCode.replace(/\s+/g, ''), newPassword.replace(/\s+/g, ''), {
        onFailure(err) {
          reject({ status: err.code, message: err.message });
        },
        onSuccess() {
          sendMessage({toEmail: email, username: email, password: newPassword, url: url})
          resolve({ status: 200, data: 'Password updated!' });
        },
      });
    });
  },  
  authenticated() {
    return new Promise((resolve, reject) => {
      const currentUser = userPool.getCurrentUser();
      authenticated = currentUser && currentUser.storage['cognito-user-token'];
      if(authenticated)
        resolve({username: currentUser.storage['cognito-user-info']});
      else 
        reject({ status: 401, message: 'Unathorized status' });
    });    
  },
  currentUserInfo(){
    return new Promise((resolve, reject) => {
      const currentuser = userPool.getCurrentUser();
      if(!currentuser){
        reject({err: 'Err'});
      }
      resolve({userToken: currentuser.storage['cognito-user-token'], userId: currentuser.storage['cognito-user-id']});
    })
  },
  userData(){
    return new Promise((resolve, reject) => {
      let username = localStorage.getItem('cognito-user-info') 
      let cognitoUser = userPool.getCurrentUser();
      if(!cognitoUser){
        reject({ status: 401, message: 'Unauthorized access' });
      }
      cognitoUser.getSession(function(err, session) {
        if (err) {
          reject({ status: err.statusCode, message: err.message });
        }
        cognitoUser.getUserAttributes(function(err, result) {
          if (err) {
            reject({ status: err.statusCode, message: err.message });
          }
          let data = { username: username }
          for (let i = 0; i < result.length; i++) {
            data[result[i].getName()] = result[i].getValue()
          }
          resolve({ status: 200, data: data });
        });
      });            
    });
  },
  updateAttributes(data){
    return new Promise((resolve, reject) => {
      let cognitoUser = userPool.getCurrentUser();
      let attributeList = [];
      if(!cognitoUser){
        reject({ status: 401, message: 'Unauthorized access' });
      }

      let keys = Object.keys(data)
      for (const key of keys) {
        attributeList.push(
          {
            Name: key,
            Value: data[key]
          }
        )
      }

      cognitoUser.getSession(function(err, session) {
        if (err) {
          reject({ status: err.statusCode, message: err.message });
        }

        cognitoUser.updateAttributes(attributeList, function(err, result) {
          if (err) {
            reject({ status: err.statusCode, message: err.message });
          }
          resolve({ status: 200, data: result });
        });
      });            
    });
  },
  signCheck() {
    return new Promise(resolve => {
      (async () => {
        const currentuser = userPool.getCurrentUser();
        authenticated = currentuser && currentuser.storage['cognito-user-token'];
        if(!authenticated) {
          return resolve(null);
        } else {
          const decoded = decoder(currentuser.storage['cognito-user-token']);

          if(Date.now() < decoded["exp"] *1000){
            return resolve(currentuser.storage['cognito-user-token']);
          } else {
            this.refreshJWTToken()
              .then(result => {
                return resolve(result.data);
              })
          }
        }
      })();
    })
  },
  signOut(){
    let username = localStorage.getItem('cognito-user-info');
    let userData = {
        Username : username,
        Pool : userPool
    };
    authenticated = false
    localStorage.removeItem('cognito-user-token');
    localStorage.removeItem('cognito-user-id');
    localStorage.removeItem('session-token');
    localStorage.removeItem('cognito-user-info');
    let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
    cognitoUser.signOut();
  },
  getUserTokens(){
    const currentuser = userPool.getCurrentUser();
    if(currentuser){
      return { accessToken: currentuser.storage['cognito-user-token'], userId: currentuser.storage['cognito-user-id'] };
    }    
    return null;
  },
  currentUser() {
    const currentuser = userPool.getCurrentUser();
    return currentuser;
  },
  refreshJWTToken(){
    return new Promise((resolve, reject) => {
      const currentuser = userPool.getCurrentUser();
      let refreshToken = currentuser.storage['session-token'];//localStorage.getItem('session-token')
      var token = new CognitoRefreshToken({ RefreshToken: refreshToken });
      let username = localStorage.getItem('cognito-user-info');
      let userData = {
          Username : username,
          Pool : userPool
      };
      let cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.refreshSession(token, (err, session) => { 
        if (err) {
          reject({ status: err.statusCode, message: err.message });
        }
        const accessToken = session.getIdToken().getJwtToken();
        dynamicLink.createDynamicLink(accessToken)
          .then(result => {
            localStorage.setItem('dynamic-link', result.data.shortLink);
          });
        localStorage.setItem('cognito-user-token', accessToken);
        
        resolve({ status: 200, data: accessToken });
      }); 
   })
  },
  updateUserStatus(username, status){
    return new Promise((resolve, reject) => {
      let userData = {
        region: loader.getConfigValue('VUE_APP_COGNITO_REGION'),
        accessKeyId: loader.getConfigValue('VUE_APP_COGNITO_ACCESS_KEY_ID'),
        secretAccessKey: loader.getConfigValue('VUE_APP_COGNITO_SECRET_ACCESS_KEY')
      };

      let params = {
        Username : username,
        UserPoolId : userPool.getUserPoolId(),
      };

      let provider = new AWS.CognitoIdentityServiceProvider(userData);

      provider[status === true ? 'adminDisableUser' : 'adminEnableUser'](params, function(err, data) {
        if (err) {
          reject({ status: err.code, message: err.message });
        }
        else {
          resolve({ status: 200, data: data });
        }
      });
    })
  },
  isAdmin() {
    const token = this.getUserTokens();
    if(!token) {
      return false;
    }
    if(!token.accessToken){
      return false;
    }
    const decoded = decoder(token.accessToken);

    if (!decoded['cognito:groups']) {
      return false;
    }

    return decoded['cognito:groups'].includes('vita-admin');
  },

  checkBots() {
    return new Promise((resolve, reject) => {
      agoraRequest({
        method: 'GET',
        url: '/botsStatus',
      })
        .then(res => {
          if (res.data.id) {
            resolve({ status: 200, data: res.data });
          }
          resolve({ status: 404, data: {} });
        })
        .catch(err => {
          console.log('aws checkBots err - ' + JSON.stringify(err));
          reject({ status: err.code, message: err.message });
        });
    })
  },

  stopBots() {
    return new Promise((resolve, reject) => {
      agoraRequest({
        method: 'POST',
        url: '/stopBots',
      })
        .then(res => {
          resolve({ status: 200, data: res.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        });
    })
  },

  startBots(pods, aCount) {
    return new Promise((resolve, reject) => {
      agoraRequest({
        method: 'POST',
        url: '/startBots',
        body: JSON.stringify({
          pods: pods,
          aCount: aCount
        })
      })
        .then(res => {
          resolve({ status: 200, data: res.data });
        })
        .catch(err => {
          reject({ status: err.code, message: err.message });
        });
    });
  },

  generateInvite(code) {
    return new Promise((resolve, reject) => {
      let url = `/profiles/api/v1/Profile/GenerateInvite`;

      let body = {
        content: code,
      };

      request({
        method: 'POST',
        url: url,
        body: JSON.stringify(body)
      })
      .then(res => {
        resolve({ status: 200, data: res.data });
      })
      .catch(err => {
        reject({ status: err.code, message: err.message });
      });
    });
  },

}
/* eslint-enable no-unused-vars */
