export const Constants = {
  DefaultConcertId: 9987,
  IsLandingMode: true,
  CustomConcertPodsList: [
    {
      "item1": 1,
      "item2": 30
    },
    {
      "item1": 2,
      "item2": 30
    },
    {
      "item1": 3,
      "item2": 30
    },
    {
      "item1": 4,
      "item2": 30
    },
    {
      "item1": 5,
      "item2": 30
    },
    {
      "item1": 6,
      "item2": 30
    },
    {
      "item1": 7,
      "item2": 30
    },
    {
      "item1": 8,
      "item2": 30
    },
    {
      "item1": 9,
      "item2": 30
    },
    {
      "item1": 10,
      "item2": 30
    },
    {
      "item1": 11,
      "item2": 30
    },
    {
      "item1": 12,
      "item2": 30
    },
    {
      "item1": 13,
      "item2": 30
    },
    {
      "item1": 14,
      "item2": 30
    },
    {
      "item1": 15,
      "item2": 30
    },
    {
      "item1": 16,
      "item2": 30
    },
    {
      "item1": 17,
      "item2": 30
    },
    {
      "item1": 18,
      "item2": 30
    },
    {
      "item1": 19,
      "item2": 30
    },
    {
      "item1": 20,
      "item2": 30
    },
    {
      "item1": 21,
      "item2": 30
    },
    {
      "item1": 22,
      "item2": 30
    },
    {
      "item1": 23,
      "item2": 30
    },
    {
      "item1": 24,
      "item2": 30
    },
    {
      "item1": 25,
      "item2": 30
    },
    {
      "item1": 26,
      "item2": 30
    },
    {
      "item1": 27,
      "item2": 30
    },
    {
      "item1": 28,
      "item2": 30
    },
    {
      "item1": 29,
      "item2": 30
    },
    {
      "item1": 30,
      "item2": 30
    }
  ],
}
