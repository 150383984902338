<template> 
  <v-list class="bg-opacity">
    <v-list-item-group>
      
        <v-list-item v-if="isAdmin" dense link to="/pod/admin" exact>
          <v-list-item-icon>
            <v-icon>far fa-envelope</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            Admin Chat
          </v-list-item-title>
        </v-list-item>

      <template v-for="item, i in menuItems">
        <v-list-item v-if="checkMenuItem(item)" :key="`menu-item-${i}`" dense link :to="item.route" exact>
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item>


        <v-divider
          v-if="separated && i + 1 < menuItems.length"
          :key="`divider-${i}`"
        >
        </v-divider>

      </template>
    </v-list-item-group>
  </v-list>
</template>

<script>
import auth from '@/cognito/aws-auth';

export default {
  props: {
    separated: { type: Boolean, default: false }
  },
  computed: {
    isAdmin() {
      return auth.isAdmin();
    },
    menuItems() {
      return [
        { title: "My Profile", icon: "far fa-user-circle", route: "/profile" },
        { title: "Invite Code", icon: "fas fa-user-plus", route: "/profile/invite", requiresAdmin: true  },
        { title: "My Friends", icon: "fas fa-user-friends", route: "/profile/friends" },
        { title: "My Wallet", icon: "fas fa-wallet", route: "/profile/wallet" },
        { title: "Calendar", icon: "far fa-calendar-alt", route: "/profile/calendar" },
        { title: "Purchases", icon: "fas fa-ticket-alt", route: "/profile/purchases" },
        { title: "Invites", icon: "fas fa-user-plus", route: "/profile/invites" },
        { title: "Notifications", icon: "far fa-bell", route: "/profile/notifications" },
        { title: "Check services", icon: "fa fa-server", route: "/services", requiresAdmin: true },
        { title: "Settings", icon: "fas fa-cog", route: "/profile/settings" },
        { title: "Log Out", icon: "fas fa-sign-out-alt", route: "/sign-out" },
      ]
    }
  },
  methods: {
    checkMenuItem(item) {
      if (item.requiresAdmin === true) {
        return this.isAdmin === true;
      }
      return true;
    },
  }
}
</script>
<style scoped lang="scss" >
.bg-opacity{
   background-color:rgba(238,238,238,0.3)!important;
}
</style>
