<template>
  <v-card tile class="mx-auto align-self-center transparent-bg" max-width="378">
      <div class="login-form">
        
        <v-col class="log-input log-google">
          <v-btn block> log in with google </v-btn>
        </v-col>
        <!-- <h2><span>OR</span></h2> -->
        <div v-show="error" class="form-error">{{ notification }}</div>
        <v-col class="log-input">
          <v-text-field
            label="Username or Email"
            v-model="form.username"
            outlined
            :rules="rules"
            hide-details="auto"
            height="60px"
            prepend-inner-icon="$vuetify.icons.userIcon"
          ></v-text-field>
        </v-col>
        <v-col class="log-input">
          <v-text-field
            label="Password"
            type="password"
            v-model="form.password"
            :rules="rules"
            hide-details="auto"
            dense
            outlined
            height="60px"
            prepend-inner-icon="$vuetify.icons.lockIcon"
            @keyup.enter="signIn"
          ></v-text-field>
        </v-col>
      </div>
    <v-card-actions>
      <v-btn
        block
        elevation="0"
        color="#FF3C11 white--text"
        @click="signIn"
        :disabled="!form.username || !form.password"
        height="60px"
      >
        Login
      </v-btn>
    </v-card-actions>

    <v-overlay :absolute="absolute" :value="overlay"> </v-overlay>
    <v-progress-linear v-if="overlay" indeterminate color="teal accent-4">
    </v-progress-linear>
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      :timeout="timeout"
      :top="top"
      :left="left"
      color="teal darken-4"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn text color="red" center v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
//import { Auth } from 'aws-amplify'

export default {
  props: {
    prevRoute: { type: String, default: '/' },
},
  name: "signin",
  data() {
    return {
      absolute: true,
      overlay: false,
      multiLine: true,
      snackbar: false,
      top: false,
      left:false,
      notification: "",
      error: false,
      timeout: 3500,
      form: {
        username: "",
        password: "",
      },
      rules: [
        // (value) => !!value || "Required.",
        // (value) => (value && value.length >= 3) || "Min 3 characters",
      ],
    };
  },
  methods: {
    async signIn() {
      const { username, password } = this.form;
      let self = this;
     self.overlay = true;
      self.$store.dispatch('auth/login', { username, password })
        .then(() => {
          self.snackbar = true;
          self.notification = `Welcome to Vitaverse!`;          
          setTimeout(self.redirect, 2300);
        })
        .catch((error) => {
          self.snackbar= true;
          self.error = true;
          self.overlay = false;
          self.notification = `ERROR! ${error.message}`;
          setTimeout(self.clear, 2500);          
        })
    },
    clear(){
      this.error = false
    },
    redirect(){
      self.overlay = false;
      this.$router.push(this.$route.query.redirect || this.prevRoute)
    }
  },
};
</script>

<style scoped>
.login-form {
  padding: 8px 0px;
  padding-top: 16px;
}

.log-input {
  padding: 10px 0px!important;
}
h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
h2 span {
  background: #fff;
  padding: 0 10px;
  color: #000;
  font-size: 0.9em;
  font-weight: 450;
}

.log-google{
  display:none
}


.v-text-field--outlined.v-input--dense .v-label {
    top: 20px;
}


>>> .v-label
{
    top: 50%!important;
    transform: translateY(-50%);
}

>>> .v-text-field .v-input__prepend-inner{
  align-self: center;
  margin-top: 0!important;
}

>>>.v-icon__component {
    height: 20px;
    width: 20px;
}

.theme--dark.v-btn.v-btn--has-bg {
  background-color: #FF3C11;
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg{
   background-color: #FF3C11!important;
   color:white!important;
}

.form-error{
  color: #FF3C11!important;
  text-align: center;
  text-transform: none;
  font-weight: 450;
}
>>> .v-text-field--outlined legend{
  display:none
}

>>> .v-input--is-focused label,
>>> .v-input--is-dirty label
{
  display:none
}

</style>
<style>
.v-overlay__scrim {
  background-color: transparent !important;
}
</style>
