<template>
  <div style="display: none"></div>
</template>

<script>
import { mapMutations } from 'vuex';
import { SET_HEADER_TRANSPARENT } from '@/store/ui';

export default {
  mounted() {
    this.toggle(true);
  },
  destroyed() { 
    this.toggle(false);
  },
  methods: {
    ...mapMutations('ui', {
      toggle: SET_HEADER_TRANSPARENT
    })
  }
};
</script>
