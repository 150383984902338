<template>
  <page-section :reversed="reversed" :header="header">


    <v-row class="wrapper" justify="center">
      <v-col cols="5" :order="reversed ? 2 : 1">
        <aside :class="['image', reversed && 'image--reversed']">
          <img v-if="image" :src="image" class="border-radius--10"/>
          <div v-else class="placeholder"></div>
        </aside>
      </v-col>

      <v-col cols="6" :order="reversed ? 1 : 2">
        <main>
          <div class="title" v-html="title"></div>
          <hr class="under-title" v-if="!header">
          <div class="subtitle" v-show="subtitle">{{subtitle}}</div>
          <hr class="under-title" v-if="header">
          <div class="description" v-html="description"></div>
        </main>

        <footer class="info-section-actions">
          <slot name="actions" />
        </footer>
      </v-col>

    </v-row>
  </page-section>
</template>

<script>
import PageSection from './PageSection';

export default {
  components: {
    PageSection
  },
  props: {
    header: { type: String},
    title: { type: String, required: true },
    subtitle: { type: String },
    image: { type: String },
    reversed: { type: Boolean, default: false },
    description: { type: String, required: true },
  },
};
</script>

<style lang="scss" scoped>

.info-section-actions > * { 
  margin: 1rem;
}

.info-section-actions > *:first-child { 
  margin-left: 0;
}

</style>

<style   lang="scss">
  img{
    width:80%;
  }
main{
.title, .subtitle {
  text-transform: uppercase;
  font-weight: bold;
}

.title,
.subtitle,
.description {
  text-align: left;
}

.description {
  margin-top: 0.5rem;
}

footer {
  display: flex;
  flex-flow: row nowrap;

  justify-content: flex-start;
  align-items: center;
  margin-top:1em;
  z-index: 10;
}

.image {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  border-radius:10px!important;

  &--reversed {
    justify-content: flex-end;
  }
}

.placeholder {
  width: 300px;
  height: 250px;
  border: solid 2px #444;
}

  .order-2{
    padding-left:3em;
  }

  header{
    margin-bottom:3em!important;
  }
}


@media screen and(max-width:850px) {
      main{
         .image--reversed{
          justify-content:center;
         }
      }
}
</style>