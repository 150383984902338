<template>
<svg width="4" height="8" viewBox="0 0 4 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0.677934 7.04922C0.522137 7.2039 0.27018 7.20316 0.1155 7.04699C-0.0391798 6.89119 -0.0384359 6.63923 0.117363 6.48455L3.0395 3.58627L0.117363 0.680167C-0.0384331 0.525115 -0.0391769 0.27353 0.1155 0.117361C0.27018 -0.038435 0.522137 -0.0391793 0.677934 0.115498L3.88222 3.29593H3.88259C3.95788 3.37084 4 3.47223 4 3.57845C4 3.6843 3.95788 3.78605 3.88259 3.86059L0.677934 7.04922Z" fill="white"/>
</svg>

</template>
<script>
export default {

}
</script>