<template>
    <svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.9119 5.26152C20.5622 4.74379 19.8615 4.62925 19.3374 4.97402C18.8122 5.31879 18.696 6.00948 19.0457 6.52606C20.0666 7.99277 20.6203 9.68856 20.6203 11.4708C20.6203 13.253 20.0666 14.978 19.0457 16.4155C18.696 16.9332 18.8122 17.6228 19.3374 17.9675C19.5413 18.1113 19.7453 18.1686 19.9788 18.1686C20.3286 18.1686 20.6784 17.9956 20.9119 17.68C22.1948 15.8405 22.8955 13.6844 22.8955 11.4416C22.8955 9.25723 22.196 7.1009 20.9119 5.26147V5.26152Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.2087 1.40819C23.8008 0.919672 23.1001 0.862402 22.6045 1.26444C22.1089 1.66648 22.0508 2.35717 22.4587 2.84569C24.4707 5.23217 25.5792 8.33752 25.5792 11.5C25.5792 14.6625 24.4706 17.7678 22.4587 20.1822C22.0508 20.6707 22.1374 21.3614 22.6341 21.7635C22.8381 21.9364 23.1013 22.0218 23.3633 22.0218C23.6846 22.0218 24.0047 21.878 24.2383 21.6197C26.5716 18.802 27.8544 15.2082 27.8544 11.5279C27.8544 7.81974 26.5715 4.22599 24.2085 1.4082L24.2087 1.40819Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8451 0.488479C13.9997 0.0572291 12.9789 0.143709 12.1904 0.718703L5.51187 5.6062C5.45376 5.66348 5.33641 5.69268 5.24982 5.69268H2.65445C1.25422 5.69268 0.117188 6.81347 0.117188 8.1937V14.8062C0.117188 16.1864 1.25422 17.3072 2.65445 17.3072H5.24982C5.33755 17.3072 5.42528 17.3364 5.51187 17.3937L12.1904 22.2812C12.6279 22.5979 13.1532 22.7697 13.7069 22.7697C14.0863 22.7697 14.4942 22.6832 14.8439 22.5114C15.6893 22.0802 16.2442 21.2177 16.2442 20.2687V2.73118C16.2168 1.7822 15.6916 0.9197 14.8451 0.48845L14.8451 0.488479ZM13.9701 20.2688C13.9701 20.4125 13.8824 20.4698 13.8242 20.499C13.7661 20.5282 13.6488 20.5563 13.5622 20.4698L6.88362 15.5823C6.41649 15.2375 5.86279 15.0646 5.27945 15.0646H2.68409C2.53825 15.0646 2.42204 14.95 2.42204 14.8063V8.19376C2.42204 8.05001 2.53825 7.93546 2.68409 7.93546H5.27945C5.86279 7.93546 6.41649 7.76251 6.88362 7.41773L13.5622 2.53023C13.6784 2.44376 13.7661 2.47295 13.8242 2.50103C13.8824 2.53023 13.9701 2.58751 13.9701 2.73126V20.2688Z" fill="white"/>
    </svg>
</template>
<script>
export default {

}
</script>