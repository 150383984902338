<template>
<svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M9.2159 8.39614C8.95432 8.13456 8.68926 7.8695 8.42769 7.60792C7.79408 6.97432 7.16048 6.34072 6.52688 5.70712C5.76191 4.94215 5.00159 4.18182 4.23661 3.41685C3.57394 2.75417 2.91129 2.09152 2.24861 1.42884C1.92891 1.10914 1.61501 0.777803 1.28599 0.466226C1.28018 0.460413 1.27669 0.456925 1.27204 0.452274C1.05696 0.237196 0.661683 0.219759 0.448944 0.452274C0.237355 0.684788 0.218753 1.04519 0.448944 1.27537C0.710525 1.53695 0.975582 1.80201 1.23716 2.06358C1.87076 2.69718 2.50436 3.33079 3.13796 3.96439C3.90293 4.72936 4.66326 5.48968 5.42823 6.25465C6.09091 6.91733 6.75356 7.57998 7.41624 8.24266C7.73594 8.56236 8.04984 8.8937 8.37886 9.20528C8.38467 9.21109 8.38816 9.21458 8.39281 9.21923C8.60788 9.43431 9.00316 9.45175 9.2159 9.21923C9.43098 8.98672 9.44493 8.62632 9.2159 8.39614Z" fill="white" stroke="white" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.5467 23.7283L17.6142 16.7959C17.2945 16.4761 16.9806 16.1448 16.6493 15.8309L16.6353 15.817C16.4202 15.6019 16.025 15.5844 15.8122 15.817C15.6006 16.0495 15.582 16.4099 15.8122 16.6401L22.7447 23.5725C23.0644 23.8922 23.3783 24.2235 23.7096 24.5374L23.7236 24.5514C23.9386 24.7665 24.3339 24.7839 24.5467 24.5514C24.7594 24.3177 24.7768 23.9573 24.5467 23.7283Z" fill="white" stroke="white" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.39595 15.7807C8.13437 16.0422 7.86932 16.3073 7.60774 16.5689C6.97414 17.2025 6.34054 17.8361 5.70693 18.4697L1.42866 22.7479C1.10896 23.0676 0.777619 23.3815 0.466041 23.7106L0.45209 23.7245C0.237013 23.9396 0.219576 24.3349 0.45209 24.5476C0.684604 24.7592 1.04501 24.7778 1.27518 24.5476C1.53677 24.286 1.80182 24.021 2.0634 23.7594C2.697 23.1258 3.3306 22.4922 3.9642 21.8586C4.72917 21.0936 5.4895 20.3333 6.25447 19.5683C6.91715 18.9056 7.5798 18.243 8.24248 17.5803C8.56218 17.2606 8.89352 16.9467 9.2051 16.6177C9.21091 16.6119 9.2144 16.6084 9.21905 16.6037C9.43412 16.3887 9.45156 15.9934 9.21905 15.7807C8.98653 15.5691 8.62613 15.5516 8.39595 15.7807Z" fill="white" stroke="white" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.7246 0.453398C23.463 0.714979 23.198 0.980035 22.9364 1.24161L18.7629 5.41512C18.106 6.07197 17.4492 6.72885 16.7923 7.38569C16.4726 7.70539 16.1413 8.01929 15.8274 8.35063C15.8216 8.35644 15.8181 8.35993 15.8134 8.36458C15.5983 8.57966 15.5809 8.97494 15.8134 9.18768C16.0459 9.39926 16.4063 9.41787 16.6365 9.18768C16.8981 8.9261 17.1632 8.66104 17.4247 8.39946L21.5982 4.22595C22.2551 3.56911 22.912 2.91223 23.5688 2.25539C23.8885 1.93568 24.2198 1.62179 24.5337 1.29045C24.5396 1.28463 24.543 1.28115 24.5477 1.27649C24.7628 1.06142 24.7802 0.666137 24.5477 0.453398C24.314 0.240648 23.9536 0.223208 23.7246 0.453398Z" fill="white" stroke="white" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.44576 8.26816V5.76631V1.77881V0.866192L0.864483 1.44747H3.36633H7.35383H8.26645C8.57103 1.44747 8.86168 1.18008 8.84773 0.866192C8.83378 0.552293 8.59196 0.284912 8.26645 0.284912H5.7646H1.7771H0.864483C0.550584 0.284912 0.283203 0.552305 0.283203 0.866192V3.36804V7.35554V8.26816C0.283203 8.57274 0.550596 8.86339 0.864483 8.84944C1.17837 8.83548 1.44576 8.59367 1.44576 8.26816Z" fill="white" stroke="white" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7341 24.7188H19.236H23.2235H24.1361C24.45 24.7188 24.7174 24.4514 24.7174 24.1375V21.6356V17.6481V16.7355C24.7174 16.4309 24.45 16.1403 24.1361 16.1542C23.8222 16.1682 23.5548 16.41 23.5548 16.7355V19.2374V23.2249V24.1375C23.7501 23.9422 23.9408 23.7515 24.1361 23.5562H21.6342H17.6467H16.7341C16.4295 23.5562 16.1389 23.8236 16.1528 24.1375C16.1679 24.4537 16.4086 24.7188 16.7341 24.7188Z" fill="white" stroke="white" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.2684 23.556H5.76656H1.77906H0.866437C1.06175 23.7513 1.25242 23.9419 1.44772 24.1372V21.6354V17.6479V16.7353C1.44772 16.4307 1.18032 16.14 0.866437 16.154C0.552537 16.1679 0.285156 16.4098 0.285156 16.7353V19.2371V23.2246V24.1372C0.285156 24.4511 0.552549 24.7185 0.866437 24.7185H3.36828H7.35578H8.2684C8.57298 24.7185 8.86364 24.4511 8.84968 24.1372C8.83573 23.8233 8.59391 23.556 8.2684 23.556Z" fill="white" stroke="white" stroke-width="0.5"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M24.7213 8.26816V5.76631V1.77881V0.866192C24.7213 0.552293 24.4539 0.284912 24.14 0.284912H21.6381H17.6506H16.738C16.4334 0.284912 16.1428 0.552305 16.1567 0.866192C16.1707 1.18009 16.4125 1.44747 16.738 1.44747H19.2399H23.2274H24.14L23.5587 0.866192V3.36804V7.35554V8.26816C23.5587 8.57274 23.8261 8.86339 24.14 8.84944C24.4539 8.83548 24.7213 8.59367 24.7213 8.26816Z" fill="white" stroke="white" stroke-width="0.5"/>
</svg>

</template>
<script>
export default {

}
</script>