<template>
<svg width="28" height="23" viewBox="0 0 28 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M20.1762 9.47059C17.5609 9.47059 15.4409 7.35066 15.4409 4.73529C15.4409 2.11993 17.5609 0 20.1762 0C22.7916 0 24.9115 2.11993 24.9115 4.73529C24.9115 7.35066 22.7916 9.47059 20.1762 9.47059ZM20.1762 7.41176C21.6544 7.41176 22.8527 6.21345 22.8527 4.73529C22.8527 3.25714 21.6544 2.05882 20.1762 2.05882C18.6981 2.05882 17.4998 3.25714 17.4998 4.73529C17.4998 6.21345 18.6981 7.41176 20.1762 7.41176ZM27.7939 17.9118C27.7939 18.4795 27.3323 18.9412 26.7645 18.9412C26.1967 18.9412 25.7351 18.4795 25.7351 17.9118C25.7351 14.8412 23.2468 12.3529 20.1762 12.3529C17.1057 12.3529 14.6174 14.8412 14.6174 17.9118C14.6174 18.4795 14.1558 18.9412 13.588 18.9412C13.0202 18.9412 12.5586 18.4795 12.5586 17.9118C12.5586 13.7039 15.9685 10.2941 20.1762 10.2941C24.384 10.2941 27.7939 13.704 27.7939 17.9118Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M7.8237 13.1765C5.20834 13.1765 3.08841 11.0565 3.08841 8.44117C3.08841 5.8258 5.20834 3.70587 7.8237 3.70587C10.4391 3.70587 12.559 5.8258 12.559 8.44117C12.559 11.0565 10.4391 13.1765 7.8237 13.1765ZM7.8237 11.1176C9.30186 11.1176 10.5002 9.91932 10.5002 8.44117C10.5002 6.96301 9.30186 5.7647 7.8237 5.7647C6.34555 5.7647 5.14723 6.96301 5.14723 8.44117C5.14723 9.91932 6.34555 11.1176 7.8237 11.1176ZM15.4413 21.6176C15.4413 22.1854 14.9797 22.647 14.4119 22.647C13.8442 22.647 13.3825 22.1854 13.3825 21.6176C13.3825 18.5471 10.8942 16.0588 7.8237 16.0588C4.75317 16.0588 2.26488 18.5471 2.26488 21.6176C2.26488 22.1854 1.80325 22.647 1.23547 22.647C0.667684 22.647 0.206055 22.1854 0.206055 21.6176C0.206055 17.4098 3.61596 14 7.8237 14C12.0314 14 15.4413 17.4099 15.4413 21.6176Z" fill="white"/>
</svg>

</template>
<script>
export default {

}
</script>