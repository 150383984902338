<template>
  <article class="not-found-page">
    <main>
      <div>
        We're sorry but the page you're looking for can't be found.
      </div>
      <v-btn
          color="#FF3C11 white--text"
          class="mt-16"
          width="250px"
          height="60px"
          @click="$router.back()"
      >
        Go back
      </v-btn>
    </main>
  </article>
</template>

<script>

export default {
  name: "NotFound"
}
</script>

<style scoped>
.not-found-page {
  display: grid;

  place-content: center;

  min-width: 100%;
  min-height: 100%;

  text-align: center;

  font-size: 2rem;
}
</style>