<template>
<svg width="32" height="27" viewBox="0 0 32 27" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M31.8334 25.9467L30.1329 12.8415C30.099 12.5456 29.8659 12.2485 29.5665 12.1495L26.6993 11.1282C26.4323 11.6221 26.1654 12.1495 25.8998 12.6434L28.4662 13.5656L29.9323 24.8598L23.832 22.9177L23.3984 16.2668C23.0989 16.398 22.7656 16.4648 22.3984 16.4648C22.1653 16.4648 21.8984 16.4314 21.6979 16.3658L22.1315 22.9512L16.7981 24.8612L16.7994 13.5001L18.9322 12.6435L18.1328 11.1283L16 12.0184L10.1326 9.61446C9.93212 9.54886 9.69903 9.51542 9.49984 9.58102L2.4335 12.1496C2.13272 12.2486 1.9335 12.5123 1.8671 12.8416L0.166568 25.9468C0.132715 26.2426 0.232975 26.5063 0.466048 26.7044C0.699121 26.9024 0.999915 26.9346 1.26551 26.869L8.89818 24.4329L15.6975 26.869C15.898 26.9346 16.0972 26.9346 16.2639 26.869L23.0632 24.4329L30.6959 26.869C30.7962 26.9024 30.8626 26.9024 30.9628 26.9024C31.1634 26.9024 31.3626 26.8368 31.4967 26.7044C31.7324 26.5063 31.8665 26.2426 31.8326 25.9468L31.8334 25.9467ZM8.13273 22.9177L2.03373 24.8598L3.49987 13.5656L8.8672 11.6556L8.13273 22.9177ZM15.1327 24.8598L9.7994 22.9498L10.5325 11.6236L15.1328 13.5002L15.1327 24.8598Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.7998 14.3887C22.0667 14.9161 22.8336 14.9161 23.0992 14.3887C23.0992 14.3887 26.1331 8.52742 26.8322 7.14482C27.5328 5.76216 27.5653 4.21486 26.8322 2.76522C25.633 0.362586 22.6669 -0.592984 20.2332 0.624979C17.7996 1.84301 16.8659 4.83994 18.0666 7.14482C19.2671 9.4497 21.7996 14.3887 21.7996 14.3887H21.7998ZM22.4664 2.73328C23.7659 2.73328 24.7998 3.75454 24.7998 5.03816C24.7998 6.32178 23.7659 7.34304 22.4664 7.34304C21.167 7.34304 20.1331 6.32178 20.1331 5.03816C20.1331 3.78668 21.167 2.73328 22.4664 2.73328Z" fill="white"/>
</svg>

</template>
<script>
export default {

}
</script>