<template>
  <div>
    <page-offset />

    <div class="home-top--banner fill-height" fluid :style="windowHeight">
      <v-img :src="require('@/assets/new/home-main.jpg')" contain />

      <div class="home-top--show">
<!--          <div>
        <v-icon size="30" class="live-icon">$vuetify.icons.liveIcon</v-icon>
          BETA SITE
        </div>
         <IconLogo /> -->
        <h1>JOHN WHITMORE</h1>
        <h2>June 21,2022 • 9pm</h2>
        <div class="home-buttons">
          <v-btn v-if="!signedIn" block small outlined  @click="$router.push('/pod/1')">BUY TICKETS</v-btn>
          <v-btn v-else block small outlined :disabled="!started" @click="watchDisabled ? move('/pod/1') : $router.push('/tickets')">BUY TICKETS</v-btn>
          <v-btn v-if="!signedIn" block small outlined  @click="$router.push('/auth')">DETAILS</v-btn>
          <v-btn v-else block small outlined :disabled="!started" @click="reserved ? move('/pod/1') : $router.push('/tickets')">DETAILS</v-btn>
        </div>
      </div>
    </div>

<div class="page-bg">
  <info-section
      :image="about.image"
      class="about info-section"
      :title="about.title"
      :description="about.description"
      id="about"
    >
      <template v-slot:actions> </template>
    </info-section>
<hr class="separator"/>
    <info-section
      reversed
      class="upcoming--show info-section"
      :header="upcomingEvent.header"
      :image="upcomingEvent.image"
      :title="upcomingEvent.title"
      :subtitle="upcomingEvent.subtitle"
      :description="upcomingEvent.description"
    >
    <template v-slot:actions>
        <v-btn class="margin-right--2" outlined :to="upcomingEvent.url"
          >SHOW DETAILS</v-btn
        >
        <!-- <v-btn outlined to="/shows">All shows</v-btn> -->
      </template>
    </info-section>
</div>
    


    <!-- <page-section header='<span class="title--underline">PAST</span> SHOWS'>
      <v-row class="shows page-section" justify="center" align="center">
        <v-col cols="4">
          <show-preview name="Show Name" />
        </v-col>

        <v-col cols="4">
          <show-preview name="Show Name" />
        </v-col>
      </v-row>
    </page-section> -->

    <!-- <page-section header='<span class="title--underline">PURCHASE</span> TICKETS' reversed /> -->
  </div>
</template>

<script>
import InfoSection from "./InfoSection.vue";
// import PageSection from './PageSection.vue';
// import ShowPreview from '../ShowPreview.vue';

import PageOffset from "../layout/PageOffset.vue";
const logo = require("@/assets/new/logo-big.svg");
const upcoming = require("@/assets/new/alfonso.jpg");
import awsAuth from "@/cognito/aws-auth";
// import IconLogo from "@/components/icons/IconLogo.vue";
import request from "@/plugins/request";

export default {
  data() {
    return {
      signedIn: false,
      windowHeight: "height:" + "100%",
      about: {
        image: logo,
        title: `<span class="title--underline">About</span> VITAVERSE`,
        description: `VitaVerse — A trailblazing digital entertainment space created with XR technology, which allows anyone to plug into a virtual concert atmosphere from anywhere in the world. Through the latest in capture render and streaming technologies, each performance connects users to each other, ushering in a new era of events and audience engagement. 
                            <br><br>
                            Created by Vita Motus — An innovative multi-disciplinary production design firm driven by artistic passion and grounded in industrial expertise. The VM studio creates ground-breaking concepts, high-tech productions, and inspirational sculptural installations for today’s most influential artists, festivals, and brands.
`,
      },
      upcomingEvent: {
        title: "ALFONSO",
        image: upcoming,
        header: `<span class="title--underline">UPCOMING</span> SHOWS <hr class="under-title-header">`,
        description: "DJ Alfonso is back to deliver the dance party of the year in VitaVerse! Taking place on Saturday December 31 2022, we move to a wild world of surreal landscapes rooted in 360° live performance. The interactive spectacle will run from 11pm - 2am, broadcasting live across the globe!",
        subtitle: "SATURDAY, December 31 2022",
        url: "/show/1",
      },
      watchDisabled: true,
      reserved: false,
      started: false,
      isAdmin: awsAuth.isAdmin()
    };
  },
  components: {
    InfoSection,
    PageOffset,
    // IconLogo,
  },
  async mounted() {
    this.$nextTick(() => {
      this.checkAuth();
    });

    if (window.innerWidth <= 850) {
      this.windowHeight = "height:" + (window.innerHeight - 100) + "px";
    } else {
      this.windowHeight = "height: auto";
    }
    window.addEventListener("resize", () => {
      if (window.innerWidth <= 850) {
        this.windowHeight = "height:" + (window.innerHeight - 100) + "px";
      } else {
        this.windowHeight = "height: auto";
      }
    });
  },
  updated() {
    this.checkAuth();
  },
  methods: {
    async move(to) {
      await request({ method:'POST', url: '/concert/api/v1/Scene/Leave' });
      this.$router.push(to);
    },
    logOut() {
      awsAuth.signOut();
      this.$router.push("/auth");
      this.signedIn = false;
    },
    async setStates(){
      const concerts = await request({ method: 'GET', url: '/concert/api/v1/Manager/GetConcertList' })
      if(concerts.status === 200){
        request({ method: 'GET', url: '/concert/api/Nats/GetSnapshotByConcertId?concertId=9987' }).then(res => {
          const startTime = new Date(res.data.start);
          const currentTime = new Date();

          const differenceTime = startTime.getTime() - currentTime.getTime();

          if(differenceTime > 900000){
            this.started = false;
            const timer = differenceTime - 900000;
            this.updateConcertState(timer, concerts);
          }
          else {
            this.started = concerts.data.length != 0;
          }
        });
      }

      awsAuth.userData()
        .then(watch => {
          if(watch.status === 200){
            if(!this.isAdmin){
              this.watchDisabled = watch.data['custom:concerts'] != undefined;  
            }
            else this.watchDisabled = true;        
          }
        });
      

      request({method: 'GET', url: `/show/api/v1/Pod/GetReservedPods`}).then(reserved => {
        if(reserved.status === 200){
          this.reserved = reserved.data.length != 0 || this.isAdmin;
        }
      });
      
    },

    updateConcertState(timer, concerts){
      setTimeout(()=> { 
        this.started = concerts.data.length != 0;
      }, timer);
    },

    checkAuth() {
      awsAuth
        .authenticated()
        .then((response) => {
          this.userName = response.username;
          this.userLogo = response.username[0].toUpperCase();
          this.signedIn = true;
          this.setStates();
        })
        .catch((err) => {
          console.log(err);
          this.signedIn = false;
        });
    },
  },
};
</script>

ShowPreview
<style lang="scss" >
  hr.separator{
    width:80%;
    margin:auto;
    border-color: rgba(255,255,255,.3);
  }

#app .description {
  text-align: left;
  margin-top: 19px;
  font-weight: 200;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 2.7px;
}
.v-btn:not(.v-btn--round).v-size--default {
  padding: 0 5px;
}
.login {
  border-left: 1px solid rgba(255, 255, 255, 0.27);
  padding-left: 24px;

  @media screen and(max-width:991px) {
    padding-left: 10px;
    margin-left: 10px;
  }
}
article.reversed {
  background: transparent;
}

#app article.about {
  padding: 5em 0;
  margin: 0;
  // background-image: url(~@/assets/05_VV_Homepage_02.png);
  background-size: cover;

  @media screen and (max-width: 850px) {
    padding: 2em 0;
  }

  .title {
    font-weight: 400;
    line-height: 74px;
    letter-spacing: 4.6px !important;
    color: #ffffff;
    font-size: 50px !important;
    margin-bottom: 0;

    @media screen and (max-width: 850px) {
      font-size: 40px !important;
      line-height: 59px;

      span {
        padding-bottom: 3px;
      }
    }
  }
}

.top-banner {
  height: 80vh;
  width: 100%;

  background-color: #eee;
}

.shows {
  margin: 2rem;
}

.home-top--banner {
  position: relative;
  font-size: 14px;
  // min-height: 400px;
  @media screen and (max-width: 850px) {
    font-size: 9px;
    height: auto !important;
  }

  & > .v-image {
    min-height: 100vh;

    @media screen and (max-width: 850px) {
      min-height: calc(100vh - 87px);
    }

    & > .v-image__image {
      background-size: cover;
    }
  }
}
.home-top--show {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  left: 50%;
  text-align: left;
  width: 50%;

  div {
    color: #ff3c11;
    font-size: 2em;
    font-size: 32px;
    line-height: 47px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: left;

    & > span {
      margin-top: 4px;
    }
  }
  h1 {
    font-weight: 900;
    font-size: 80px;
    line-height: 1;
    letter-spacing: 1.57px;
    color:#ec542d;
  }
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.5;
    letter-spacing: 1.57px;
    text-transform: uppercase;
  }
  .live-icon {
    margin-right: 10px;
  }
  .home-buttons {
    display: flex;
    justify-content: space-between;
    max-width: 300px;

    margin-top: .5em;

    & > button {
      flex: 1;

      &:first-child {
        margin-right: 30px;
      }

      & > span {
        font-weight: 300 !important;
        font-size:12px!important;
      }
    }
  }
  .v-btn--block {
    min-width: auto !important;
  }
  @media screen and (max-width: 850px) {
    width: 80%;
  }
}
.upcoming--show.info-section {
  & > header {
    font-weight: 400;
    font-size: 50px !important;
    line-height: 74px;
    text-align: center;
    letter-spacing: 4.6px;
    color: #ffffff;
    margin-bottom: 2em !important;

    @media screen and (max-width: 850px) {
      letter-spacing: 0 !important;
      margin-bottom: 1em !important;
      font-size: 40px !important;
      line-height: 59px;

      span {
        padding-bottom: 3px;
      }
    }
  }
}
.v-application {
  div.title {
    font-weight: 500;
    font-size: 40px !important;
    line-height: 1;
    letter-spacing: 4.6px !important;
    color: #ffffff;
    margin-bottom: 10px;

    @media screen and (max-width: 850px) {
      font-size: 30px !important;
      letter-spacing: 0 !important;
      margin-bottom: 20px;
    }
  }

  div.subtitle {
    font-weight: 300;
    font-size: 30px;
    line-height: 1;
    letter-spacing: 3.68px;
    padding-bottom: 15px;

    @media screen and (max-width: 850px) {
      font-size: 22px;
      line-height: 27px;
    }
  }

  div.description {
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 2.7px;
  }

  .upcoming--show {
    div.title {
      margin-bottom: 0;
      line-height: 58px;
      letter-spacing: 0 !important;

      @media screen and (max-width: 850px) {
        font-size: 40px !important;
        line-height: 45px;
        letter-spacing: 2px !important;
        font-weight: 400;
      }
    }

    div.subtitle {
      @media screen and (max-width: 850px) {
        font-size: 22px;
        line-height: 50px;
        letter-spacing: 2px !important;
      }
    }
  }
}
#app .info-section-actions {
  margin-top: 2em;

  a {
    padding: 12px 50px;
    height: auto;

    span {
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      text-align: center;
      letter-spacing: 2px;
    }
  }
}
.upcoming--show .order-2 {
  flex: 1;
  max-width: unset;
}
@media screen and (max-width: 850px) {
  .home-top--show {
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    text-align: center;
    width: 100%;
    & > div {
      font-size: 24px;
      line-height: 28px;
    }

    & > h1 {
      letter-spacing: 0;
      margin: 20px 0;
      font-size: 36px;
      line-height: 45px;
    }

    & > h2 {
      font-size: 24px;
      line-height: 32px;
    }

    & > svg {
      width: 100%;
    }
    .home-buttons{
      justify-items: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  main .order-2 {
    padding-left: 0 !important;
    padding-right: 0;
  }
  .about header,
  .about main {
    padding: 0 1em;
  }
  .home-top--banner {
    padding-top:87px;
    .v-responsive {
      // min-height: 800px;
      height: 100%;
      .v-image__image--contain {
        background-size: cover !important;
        // background-image: url(~@/assets/dynamic/home_main-crop.png) !important;
      }
    }
  }

  main header {
    margin-bottom: 1em !important;
  }
  .upcoming--show.info-section {
    .row {
      flex-direction: column-reverse;
    }
  }
  .about.info-section {
    .image {
      text-align: center;
      justify-content: center;
      img {
        max-width: 200px !important;
      }
    }
  }
  .info-section {
    .row {
      flex-direction: column;

      .col {
        max-width: 100%;
      }
      .col-4 {
        flex: 100%;
        max-width: 100% !important;
      }
      .col-6 {
        flex: 100%;
        max-width: 100% !important;
        padding-left: 0 !important;
      }
    }
  }
}
</style>
