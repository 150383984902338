<template>
<svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_337_3348" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="8" height="16">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H7.3846V15.8242H0V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_337_3348)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.59613 3.06479V5.24343H0V7.90752H1.59613V15.8242H4.8749V7.90752H7.07516C7.07516 7.90752 7.28123 6.63015 7.38092 5.23341H4.88738V3.41187C4.88738 3.13969 5.24484 2.77345 5.59824 2.77345H7.38462V0H4.9556C1.51508 0 1.59613 2.66655 1.59613 3.06479Z" fill="white"/>
</g>
</svg>


</template>
<script>
export default {

}
</script>