<template>
  <v-card
    class="mx-auto align-self-center  transparent-bg restore-password"
    max-width="378"
  >     
    <v-card-text v-if="!reveal">
      <div>
        <v-col class="log-input log-google">
           <v-btn block>
             log in with google
           </v-btn>
         </v-col>
        <div class="form-error">{{ notification }}</div>
        <v-col class="log-input">
          <v-text-field label="Email"
            v-model="form.email"
            :rules="emailConfirmationRules"
            outlined
            hide-details="auto"
            height="60px"
            prepend-inner-icon="$vuetify.icons.userIcon"
            dense
            @keyup.enter="confirmRestore"
          >
          </v-text-field>
        </v-col>
      </div>
    </v-card-text>
    <v-card-actions v-if="!reveal">
      <v-btn
        text
        color="#FF3C11 white--text"
        @click="confirmRestore"
        width="100%"
        height="60px"
        :disabled="!form.email"
      >
        Restore password
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <v-card
        v-if="reveal"
        class="transition-fast-in-fast-out align-self-center v-card--reveal"
        style="height: 100%;"
      >
        <v-card-text class="pb-0">
          <p>Please, enter the received code from {{restoreEmail}}</p>
          <div>
            <v-text-field
              label="Code"
              v-model="form.authCode"
              hide-details="auto"
              height="60px"
              outlined
              prepend-inner-icon="$vuetify.icons.keyboardIcon"
              dense
            ></v-text-field>
            <v-text-field
              label="Enter new password"
              type="Password"
              v-if="form.authCode"
              v-model="form.newPassword"
              hide-details="auto"
              height="60px"
              outlined
              prepend-inner-icon="$vuetify.icons.keyboardIcon"
              dense
              @keyup.enter="confirmPassword"
            >
            </v-text-field>
          </div>
        </v-card-text>

        <v-card-actions class="pt-0">
          <v-btn
            text
            color="teal accent-4"
            height="60px"
            @click="confirmPassword"
          >
            Restore
          </v-btn>
          <v-btn
            text
            color="red darken-2"
            height="60px"
            @click="reveal = false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-expand-transition>
    <v-overlay
      :absolute="absolute"
      :value="overlay"
    />
    <br>
    <v-progress-linear
      v-if="overlay"
      indeterminate
      color="teal accent-4"
    />
    <v-snackbar
      v-model="snackbar"
      :multi-line="multiLine"
      color="teal darken-4"
    >
      {{ notification }}

      <template v-slot:action="{ attrs }">
        <v-btn
          text
          color="red"
          center
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>    
  </v-card>
</template>

<script>
//import { Auth } from 'aws-amplify'
import awsAuth from '@/cognito/aws-auth'
export default {
  name: 'restore',
  props: ['toggle'],
  data() {
    return {
      formState: 'restore',
      reveal: false,
      absolute: true,
      overlay: false,
      multiLine: true,
      snackbar: false,
      notification: '',
      restoreEmail: '',
      rules: [
        value => !!value || 'Required.',
        value => (value && value.length >= 3) || 'Min 3 characters',
      ],
      emailConfirmationRules:[
        v => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must match',
        v => !!v || 'E-mail is required'
      ],
      form: {
        email: ''
      }
    }
  },
  methods: {
    async confirmRestore() {
      const { email } = this.form;
      let self = this;
      self.overlay = true;
      awsAuth.restorePassword(email)
        .then((result) => {
          self.restoreEmail = result.data;
          self.snackbar = true;
          self.reveal = true;
          self.overlay = false;
          self.formState = 'confirmRestore';          
          self.notification = `Please, enter code and set new password `;            
        })
        .catch((error) => {
          self.snackbar = true;
          self.overlay = false;
          self.notification = `ERROR! ${error.message}`;          
        })
    },
    async confirmPassword(){
      const { email, authCode, newPassword } = this.form
      const username = email.substring(0, email.lastIndexOf("@"));
      const url = `${window.location.origin}/#/auth`
      let self = this
      self.overlay = true      
      awsAuth.confirmPassword(email, username, url, authCode, newPassword)
        .then(()=>{
          self.snackbar = true
          self.notification = 'Successfully! Password restored.'
          setTimeout(self.redirect, 2300)          
        })
        .catch(error=>{
          self.snackbar = true
          self.overlay = false;
          self.notification = `ERROR! ${error.message}`
        })
    },
    redirect(){
      this.snackbar = false;
      this.overlay = false;
      this.toggle('signIn')
      //this.$router.push(this.$route.query.redirect || "/auth")
    }
  }
}
</script>

<style scoped>
#app .v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: static;
  box-shadow: none;
  background: transparent;
  width: 100%;
}
#app .v-card--reveal p {
  font-size: 24px;
  line-height: 32px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 1.4px;
  color: #FFFFFF;
}
#app .v-card--reveal >>> .v-input:first-child {
  margin-bottom: 20px;
}
#app .v-card--reveal .v-card__actions {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

#app .v-card--reveal .v-card__actions > button {
  width: 100%;
  margin: 0 0 10px;
}

#app .v-card--reveal .v-card__actions > button:last-child {
  background-color: #000000!important;
}

#app .v-card--reveal .v-card__actions > button >>> span {
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 2px;
  color: #FFFFFF;
  text-transform: capitalize;
}

#app .restore-password ~ >>> .sign-options {
  display: none;
}
.log-input {
  padding: 5px 0px 5px 0px;
}
h2 {
   width: 100%; 
   text-align: center; 
   border-bottom: 1px solid #000; 
   line-height: 0.1em;
   margin: 10px 0 20px; 
} 
h2 span { 
    background:#fff; 
    padding:0 10px; 
}

.log-google{
  display:none;
}

.login-form {
  padding: 8px;
  padding-top: 16px;
}

.log-input {
  padding: 20px 0px 0px 0px!important;
  height: 90px;
}
h2 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin: 10px 0 20px;
}
h2 span {
  background: #fff;
  padding: 0 10px;
  color: #000;
  font-size: 0.9em;
  font-weight: 450;
}

.log-google{
  display:none
}


.v-text-field--outlined.v-input--dense .v-label {
    top: 20px;
}


>>> .v-label
{
    top: 50%!important;
    transform: translateY(-50%);
}

>>> .v-text-field .v-input__prepend-inner{
  align-self: center;
  margin-top: 0!important;
}

>>>.v-icon__component {
    height: 20px;
    width: 20px;
}

.v-btn.v-btn--has-bg {
  background-color: #FF3C11;
}

.v-btn{
   background-color: #FF3C11!important;
   color:white!important;
}

.form-error{
  color: #FF3C11!important;
  text-align: center;
  text-transform: none;
  font-weight: 450;
}
>>> .v-text-field--outlined legend{
  display:none
}

>>> .v-input--is-focused label,
>>> .v-input--is-dirty label
{
  display:none
}
</style>