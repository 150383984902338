import axios from 'axios'
import auth from '@/cognito/aws-auth'
import loader from '@/config.loader'

export default function request (config){
  return new Promise((resolve, reject) => {
    (async ()=> {
      const sign = await auth.signCheck();
      var token = config.token || sign;
      var url = loader.getConfigValue('VUE_AGORA_API_URL')
      const options = {
        url: `${url}${config.url}`,
        method: config.method,
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': config.type || 'application/json;charset=UTF-8'
        },
        data: config.body
      }
      axios(options)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })();
  })
}