import auth from '@/cognito/aws-auth';

const state = {
  user: null
};

const getters = {
  authenticated: state => state.user !== null
};

const SET_USER = "SET_USER"

const mutations = {
  [SET_USER](state, user) {
    state.user = user;
  }
};

const actions = {
  async login(context, { username, password }) {
    await auth.signIn(username, password);

    context.commit(SET_USER, auth.currentUser());
  },
  async logout(context) {
    await auth.signOut();

    context.commit(SET_USER, null);
  },
  async signup(context, { username, email, password }) {
    await auth.signUp(username, password, email)
    
    context.commit(SET_USER, auth.currentUser());
  },
  async recover(context) {
    const info = await auth.authenticated();


    if (info) { 
      context.commit(SET_USER, info);
    } 
  }
};

export default { namespaced: true, state, getters, mutations, actions };