<template>
  <v-expand-transition>
  <div class="auth-form pa-10 align-self-center" style="margin: 0 auto; width: 100%; max-width: 460px;">
        <v-btn to="/" class="btn-logo">
          <v-img
            :src="require('@/assets/login-logo.svg')"
            contain
            class="login-logo"
            max-width="296"
            width="100%"
          />
        </v-btn>
    <transition name="component-fade" mode="out-in" appear>
      <sign-up v-if="formState === 'signup'" :toggle="toggle"></sign-up>
    </transition>
    <transition name="component-fade" mode="out-in" appear>
      <sign-in v-if="formState === 'signIn'" :prev-route="prevRoute"></sign-in>
    </transition>
    <transition name="component-fade" mode="out-in" appear>
      <restore-password v-if="formState === 'restore'" :toggle="toggle"></restore-password>
    </transition>
    <div v-if="formState === 'signIn' || formState === 'restore'" class="sign-options">
<!--      <p @click="toggle('signup')">-->
<!--        Don't have an account? <span class="bold-txt">Sign Up </span>        -->
<!--      </p>-->
      <p  v-if="formState != 'restore'" @click="toggle('restore')"><span>Forgot Password?</span></p>
    </div>
    <div v-if="formState === 'signup' || formState === 'restore'" class="sign-options">
      <p v-if="formState === 'signup'">
        By creating an account, you agree to the <br/>
          <span class="bold-txt">Terms and Use</span> and 
          <span class="bold-txt">Privacy Policy</span>
      </p>
      <p @click="toggle('signIn')"> <span class="bold-txt">I am already a member</span></p>
    </div>
  </div>
  </v-expand-transition>
</template>

<script>
import SignUp from "./SignUp";
import SignIn from "./SignIn";
import RestorePassword from "./RestorePassword";

export default {
  name: "auth",
  components: {
    SignUp,
    SignIn,
    RestorePassword
  },
  data() {
    return {
      formState: '',
      prevRoute: null,
    };
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevRoute = from.path;
    })
  },
  async mounted() {
    this.formState = (this.$route.query.state) ? (this.$route.query.state) : 'signIn'
  },
  methods: {
    toggle(data) {
      this.formState = data;
      const routeConfig = 
        (this.formState === "signup")
          ? { path: '/auth', query: { state: 'signup', from: 'web' } } : 
        (this.formState === "restore") 
          ? { path: '/auth', query: { state: 'restore', from: 'web' } } : 
          { path: '/auth' };
      this.$router.push(routeConfig);
    },
  },
};
</script>

<style scoped>
.auth-form {
  margin: 0 auto;
  width: 100%;
  max-width: 460px;
}
.toggle {
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  padding: 0.5rem;
  /* font-style: oblique 20deg; */
}
.bold-txt {
  font-weight: 400;
}

.login-logo{
    width: 15em;
    margin: auto;
    margin-bottom: 3em;
}

.sign-options {
  display: grid;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0 1rem 0;
  cursor: pointer;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 2px;
}

.sign-options > p {
  margin-bottom: 0.5rem;
  text-align: center;
  font-weight: 200;
  line-height: 25px;
} 

</style>