<template>
  <svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.7069 11.4596V2.43231C27.7069 1.10937 26.5642 0.0338745 25.1588 0.0338745H6.71479C5.30931 0.0338745 4.16532 1.10941 4.16532 2.43231V11.4596C1.6504 12.4687 0 13.8711 0 15.638C0 18.7786 5.20682 20.7656 11.2271 21.4453V23.6614C11.4899 23.7982 11.5494 23.8294 11.8122 23.9661L11.8883 23.9206L16.7134 21.0612V20.4531L11.8897 17.5938L11.8136 17.5482C11.5508 17.6849 11.4913 17.7162 11.2285 17.8529V19.543C5.43099 18.8698 2.02387 17.0339 2.02387 15.638C2.02387 14.983 2.77226 14.2317 4.16528 13.5481V14.0065C4.16528 15.3294 5.30931 16.4062 6.71476 16.4062H25.1587C26.5642 16.4062 27.7068 15.3294 27.7068 14.0065V13.5481C29.0998 14.2317 29.8482 14.983 29.8482 15.638C29.8482 17.0859 26.1798 19.009 19.9824 19.6146V21.5157C26.2793 20.9089 31.8721 18.892 31.8721 15.638C31.8721 13.8711 30.2218 12.4687 27.7068 11.4597L27.7069 11.4596ZM25.6844 14.0065C25.6844 14.2747 25.4437 14.5013 25.1588 14.5013L6.71479 14.5026C6.42983 14.5026 6.18912 14.276 6.18912 14.0078L6.18773 2.43214C6.18773 2.16391 6.42843 1.93734 6.71341 1.93734H25.1574C25.4424 1.93734 25.6831 2.16391 25.6831 2.43214L25.6844 14.0065Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.9364 2.80603C12.7644 2.80603 10.1846 5.23443 10.1846 8.22003C10.1846 11.2044 12.7645 13.634 15.9364 13.634C19.1083 13.634 21.6882 11.2056 21.6882 8.22003C21.6882 5.23436 19.1083 2.80603 15.9364 2.80603ZM15.9364 11.9207C13.7687 11.9207 12.0048 10.2605 12.0048 8.22136C12.0048 6.181 13.7686 4.5207 15.9364 4.5207C18.1042 4.5207 19.868 6.18086 19.868 8.22136C19.868 10.2604 18.1042 11.9207 15.9364 11.9207Z" fill="white"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.48242 4.74331H9.34853V2.83838H7.48242V4.74331Z" fill="white"/>
  </svg>
</template>
<script>
export default {

}
</script>