import Email from '@/assets/js/smtp'
import message from '@/assets/js/message'

export default function sendEmail (config){
  return new Promise((resolve, reject) => {
    console.log(config)
    Email.send({
        SecureToken : "b9b82a74-c4bd-40fb-b803-b05ef2b5473c",
        To : config.toEmail, //'nurkassym@icvr.io',
        From : "service@icvr.io",
        Subject : "Vita Verse login credentials",
        Body : message.body(config.username, config.password, config.url)
    }).then( message => {
        console.log(message)
        resolve(message)
      })
      .catch(error => {
        console.log(error)
        reject(error)
      });
  })
}