<template>
  <v-menu offset-y open-on-hover>
    <template v-slot:activator="{ on }">
      <div class="profile" v-on="on">
        <div class="profile-username">{{ user.username }}</div>
        <div class="profile-avatar">
          <img src="" />
          <v-icon>
            far fa-user-circle
          </v-icon>
        </div>
      </div>
    </template>

    <div class="profile-menu">
      <profile-menu />
    </div>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import ProfileMenu from '../ProfileMenu.vue';

export default {
  components: { ProfileMenu },
  computed: {
    ...mapState({
      user: state => state.auth.user
    })
  }
};
</script>

<style lang="scss" scoped>
.profile {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 0.8rem;
  border-radius: 0.25rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: rgba($color: #666, $alpha: 0.3);
  }
}

.profile-username {
  margin-right: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 5.6px;
}

.v-list{
  background-color:rgba(0,0,0,.6)!important;
}
</style>
