// import Home from './components/Home'
import HomePage from './components/homepage/HomePage';
import AuthComponent from './components/User/Auth';
import SignOut from './components/User/SignOut';
import SignUp from './components/User/SignUp';
import SignUpConfirmation from './components/User/SignUpConfirmation';
import Landing from '@/components/homepage/Landing';
import NotFound from '@/components/404';
import {Constants} from "@/config/constants";
import TestMailChimp from "@/components/subscribe/TestMailChimp";
import PrivacyPolicy from "@/components/privacyPolicy/PrivacyPolicy";

const routes = [
  {
    path: '/',
    name: 'Main',
    component: HomePage,
    meta: { requiresAuth: Constants.IsLandingMode },
  },
  {
    path: '/privacy-policy',
    component:  PrivacyPolicy,
  },
  {
    path: '/mailchimp',
    name: 'Mailchimp',
    component: TestMailChimp,
  },
  {
    path: '/landing',
    name: 'Landing',
    component: Landing,
  },
  {
    path: '/404',
    name: '404',
    component: NotFound,
  },
  {
    path: '/auth',
    name: 'Login',
    component: AuthComponent,
    meta: { public: true, disableIfLoggedIn: true }
  },
  {
    path: '/sign-out',
    component: SignOut
  },
  {
    path: '/sign-up',
    component: SignUp
  },
  {
    path: '/sign-up-confirmation',
    component: SignUpConfirmation
  },
  {
    path: '/about',
    component: () => import('@/components/about/AboutPage'),
    meta: { requiresAuth: Constants.IsLandingMode },
  },
  {
    path: '/contact',
    component: () => import('@/components/contact/ContactPage')
  },
  {
    path: '/socket',
    component: () => import('@/components/pod/TestSocket'),
    meta: { requiresAuth: Constants.IsLandingMode },
  },
  {
    path: '/shows',
    component: () => import('@/components/shows/ShowsPage'),
    meta: { requiresAuth: Constants.IsLandingMode },
  },
  {
    path: '/show/:id',
    component: () => import('@/components/shows/ShowDetails'),
    meta: { requiresAuth: Constants.IsLandingMode },
  },
  {
    path: '/show-info',
    component: () => import('@/components/show-info/ShowInfoPage'),
    meta: { requiresAuth: Constants.IsLandingMode },
  },
  {
    path: '/tickets',
    meta: { requiresAuth: true },
    component: () => import('@/components/tickets/Wrapper'),
    children: [
      {
        path: '',
        component: () => import('@/components/tickets/TicketsPage')
      },
      {
        path: 'spectator',
        component: () => import('@/components/tickets/NonInteractivePage')
      },
      {
        path: 'purchase',
        component: () => import('@/components/tickets/PurchasePage')
      },
      {
        path: 'checkout',
        component: () => import('@/components/tickets/CheckoutPage')
      },
      {
        path: 'confirmation',
        component: () => import('@/components/tickets/TicketsConfirmation')
      }
    ]
  },
  {
    path: '/profile',
    component: () => import('@/components/profile/ProfileWrapper'),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: () => import('@/components/profile/MyProfile')
      },
      {
        path: 'invite',
        component: () => import('@/components/profile/Invite'),
        meta: { admin: true },

      },
      {
        path: 'friends',
        component: () => import('@/components/profile/Friends')
      },
      {
        path: 'wallet',
        component: () => import('@/components/profile/Wallet')
      },
      {
        path: 'calendar',
        component: () => import('@/components/profile/Calendar')
      },
      {
        path: 'purchases',
        component: () => import('@/components/profile/Purchases')
      },
      {
        path: 'invites',
        component: () => import('@/components/profile/Invites')
      },
      {
        path: 'notifications',
        component: () => import('@/components/profile/Notifications')
      },
      {
        path: 'settings',
        component: () => import('@/components/profile/Settings')
      }
    ]
  },
  {
    path: '/pod/admin',
    meta: { requiresAuth: true, admin: true },
    component: () => import('@/components/pod/PodAdmin.vue'),
  },
  {
    path: '/pod/:id',
    component: () => import('@/components/pod/PodWrapper'),
    meta: { requiresAuth: true, checkBrowser: true, checkDevice: true },
    children: [
      { path: '', component: () => import('@/components/pod/PixelPodPage')},
      { path: 'countdown', component: () => import('@/components/show-info/ShowInfoPage')},
      { path: 'end', name: 'end', component: () => import('@/components/show-info/ShowEndedPage')}
    ]
  },
  {
    path: '/px-stream',
    component: () => import('@/components/pixel-streaming/PixelStreamingPage'),
    meta: { requiresAuth: Constants.IsLandingMode },
  },
  {
    path: '/streaming/:id',
    component: () => import('@/components/pod/PodWrapper'),//import('@/components/pixel-streaming/PixelStreamingPage')
    meta: { requiresAuth: true },
    children: [
      { path: '', component: () => import('@/components/pod/PodPage')},
      { path: 'countdown', component: () => import('@/components/show-info/ShowInfoPage')},
      { path: 'end', name: 'end', component: () => import('@/components/show-info/ShowEndedPage')}
    ]
  },
  {
    path: '/browser-notice',
    component: () => import('@/components/BrowserNotice.vue'),
    meta: { requiresAuth: Constants.IsLandingMode },
  },

  {
    path: '/zoom/meeting/:id',
    component: () => import('@/components/meeting/ZoomMeeting'),
    meta: { requiresAuth: Constants.IsLandingMode },
  },

  {
    path: '/services',
    redirect: '/services/servers',
  },

  {
    path: '/services/servers',
    component: () => import('@/components/profile/monitoring/Services'),
    meta: { requiresAuth: true,  admin: true }
  },

  {
    path: '/services/pods',
    component: () => import('@/components/profile/monitoring/Pods'),
    meta: { requiresAuth: true,  admin: true }
  },
  {
    path: '*',
    component: NotFound
  }
];

export default routes;
