<template>
  <div></div>
</template>
  
<script>
export default {
  mounted() {
    if (this.$store.getters['auth/authenticated']) { 
      this.$store.dispatch('auth/logout')
    }

    this.$router.push('/');
  }
};
</script>
