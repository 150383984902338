<template>
<svg width="19" height="13" viewBox="0 0 19 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_337_3345" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="19" height="13">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H18.8709V12.9999H0V0Z" fill="white"/>
</mask>
<g mask="url(#mask0_337_3345)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.7332 5.77955C17.7931 5.69211 18.5117 5.21027 18.7887 4.5565C18.4062 4.79134 17.2192 5.04736 16.564 4.8035C16.5317 4.64939 16.496 4.50324 16.4608 4.37115C15.9612 2.53731 14.2514 1.05992 12.4597 1.23835C12.6048 1.17985 12.7518 1.12534 12.8984 1.07627C13.0953 1.00561 14.2527 0.817323 14.0705 0.409081C13.9164 0.0499032 12.5032 0.680613 12.2371 0.763016C12.5883 0.630919 13.1695 0.403629 13.2318 0C12.6931 0.0735968 12.1649 0.328355 11.7567 0.698435C11.9041 0.53971 12.0159 0.346387 12.0396 0.138177C10.6035 1.05531 9.76477 2.90445 9.08604 4.69845C8.55283 4.18202 8.08064 3.77503 7.65688 3.54921C6.46801 2.91158 5.0466 2.24648 2.81501 1.41805C2.74665 2.15653 3.18026 3.13803 4.42931 3.79097C4.15862 3.75469 3.66399 3.83563 3.26791 3.9304C3.42936 4.77624 3.95565 5.473 5.38146 5.80995C4.72999 5.85294 4.39325 6.00118 4.08817 6.32052C4.38465 6.90866 5.10888 7.60123 6.41097 7.45885C4.96336 8.08285 5.82094 9.23902 6.99912 9.06645C4.98915 11.1427 1.81988 10.9905 -0.00012207 9.25369C4.75159 15.7283 15.0803 13.0828 16.6193 6.84639C17.7726 6.85624 18.4507 6.44695 18.8708 5.99573C18.2066 6.10853 17.244 5.99216 16.7332 5.77955Z" fill="white"/>
</g>
</svg>


</template>
<script>
export default {

}
</script>