import agoraRequest from '@/services/agora-request'
import loader from '@/config.loader';

const prefix = loader.getConfigValue('DL_PREFIX_URL');
const androidPackage = loader.getConfigValue('DL_ANDROID_PACKAGE');
const androidFallback = loader.getConfigValue('DL_ANDROID_FALLBACK_URL');
const iosBundle = loader.getConfigValue('DL_IOS_BUNDLE');
const iosFallback = loader.getConfigValue('DL_IOS_FALLBACK_URL');

function createDynamicLink(token) {
  return new Promise((resolve, reject) => {
    const body = {
      prefix: prefix,
      link: `${prefix}/jwt=${token}`,
      androidPackageName: androidPackage,
      androidFallbackLink: androidFallback,
      iosBundleId: iosBundle,
      iosFallbackLink: iosFallback
    }

    agoraRequest({
      method: 'POST',
      url: `/link/create`,
      token: token,
      body: JSON.stringify(body)
    })
      .then(res => {
        resolve({ status: 200, data: res.data.data });
      })
      .catch(err => {
        console.log(`failed to create deeplink: ${err.message}`)
        reject({ status: err.statusCode, message: err.message });
      })
  })
}

export default { createDynamicLink }

