export default {
  body(login, password, url) {

    return `
    <table width="100%" border="0" cellpadding="0" cellspacing="0" bgcolor="white" style="background-color: #e1e1e1; font-family: sans-serif;">
    <tbody>
        <tr>
        <td align="center">
            <table width="550" align="center" style="background-color: #efefef;">
            <tbody>
                <tr>
                <td align="center">
                    <table align="center" border="0" cellpadding="0" cellspacing="0" class="col-550" width="550">
                    <tbody>
                        <tr style="background-image:url('${url}/img/05_VV_Homepage_02.041a2987.png') ;">
                        <td align="center" style="
                            background-color: #190021;
                            height: 50px;
                            background: #9A00CB;
                            opacity: 0.47;
                            mix-blend-mode: normal;"
                        >
                            <a href="#" style=" text-decoration: none; opacity: 1.0" >
                            <img src="${url}/img/Union.426c6ec8.png"  style="margin: 0.5rem; width: 65px; height: 50px;" />
                            </a>
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </td>
                </tr>
                <tr style="height: 300px;">
                <td align="center" style="
                    border: none;
                    padding-right: 20px;
                    padding-left: 20px"
                >
                    <p style="
                    font-weight: bolder;
                    font-size: 25px;
                    letter-spacing: 0.025em;
                    color:orangered;"
                    >
                    Login: ${login}
                    </p>
                    <p style="
                    font-weight: bolder;
                    font-size: 25px;
                    letter-spacing: 0.025em;
                    color:orangered;"
                    >
                    Password: ${password}
                    </p>

                    <p class="data" style="text-align: justify-all;
                                align-items: center; 
                                font-size: 14px;
                                padding-bottom: 12px;">
                    Please, use the following login credentials:<br>
                    <a href="${url}/#/auth">
                        <button style="
                            cursor:pointer;
                            padding: 0.75rem 2rem;
                            margin: 2rem;
                            border-radius: 0.5rem;
                            border: none;
                            background-color: orangered;
                            color: #eee;
                            text-transform: uppercase;
                            font-weight: bolder;
                            font-size: 0.75rem;
                            letter-spacing: -0.01rem">
                        Link to the VitaVerse
                        </button>
                    </a>
                    <br>
                    </p>
                </td>
                </tr>
            </tbody>
            </table>
        </td>
        </tr>
        <tr>
        <td align="center" style="height: 40px;
                    width: 100%;
                    padding: 5px;
                    border: none; 
                    border-bottom: 2px solid #361B0E;
                    background-color: #111;
                    color: #eee">
            <p class="data" style="text-align: center;
                                align-items: center;
                                width: 100%;
                                padding: 1rem;
                                color: #ddd;
                                font-size: 15px;
                                font-weight: bold;
                                padding-bottom: 12px;">
            © Copyright VitaVerse 2023. All Rights Reserved.
            </p>
        </td>
        </tr>
    </tbody>
    </table>`
  }
}
