<template>
  <article class="page" v-bind:class="'route-'+$route.path.slice('-3')">
    <header v-show="!hide">
      <page-header v-if="$route.path !== '/auth'" :transparent="transparentHeader" />
    </header>

    <v-main>
      <slot />
    </v-main>

    <footer v-show="!hide" v-bind:class="'footer-'+$route.path.replace('/','')">
      <page-footer />
    </footer>
  </article>
</template>

<script>
import PageHeader from './PageHeader';
import PageFooter from './PageFooter';
import { mapState } from 'vuex';

import loader from '@/config.loader'

export default {
  props: {
    transparentHeader: { type: Boolean, default: false }
  },

  computed: {
    ...mapState({
      hide: state => state.ui.hideUI
    })
  },
  components: {
    PageHeader,
    PageFooter
  },
  created() {
    this.openZendesk();
  },
  methods: {
    async openZendesk() {
      const key = loader.getConfigValue('VUE_ZENDESK_KEY');

      this.$zendesk.$on('loaded', () => {
        this.$zendesk.setLocale('en');
        this.$zendesk.show()
      });
      this.$zendesk.load(key);
    }

  }
};
</script>

<style lang="scss" scoped>
.page {
  background-image:url(~@/assets/new/page-bg.jpg);
  background-repeat: repeat-y;
  background-size: cover;
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: stretch;
  position: relative;

  main {
    flex-grow: 2;
  }

  header, footer {
    z-index: 123 !important;
  }
}

.page.route-end {
  // background: url("~@/assets/09_VW_HomePage_04.png");
  background-size: cover;
}

.page.route-pod1end {
  // background: url("~@/assets/09_VW_HomePage_04.png");
  background-size: cover;
}

@media screen and (max-width:850px){
      .v-main{
        margin-top:0px;
      }
}
</style>
