import Vue from 'vue';
import VueRouter from 'vue-router';
import awsAuth from '@/cognito/aws-auth';
import store from './plugins/vuex';
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import './plugins/zendesk';

import App from './App.vue';
import routes from './router';
import vuetify from './plugins/vuetify';
import Chat from 'vue-beautiful-chat';
import VueCountdownTimer from 'vuejs-countdown-timer';
import ReadMore from 'vue-read-more';
import loader from "@/config.loader";

Sentry.init({
  Vue,
  dsn: loader.getConfigValue("VUE_VITAMOTUS_SENTRY_API"),
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
});

Vue.use(ReadMore);
Vue.use(VueCountdownTimer);
Vue.use(VueRouter);
Vue.use(Chat);
Vue.config.productionTip = false;

/*import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: "pk_test_VX9y522nUDtpnwGYDooUjxiu",
  stripeAccount: process.env.STRIPE_ACCOUNT,
  apiVersion: process.env.API_VERSION,
  locale: process.env.LOCALE,
};

Vue.use(StripePlugin, options);*/

const router = new VueRouter({
  routes,
  scrollBehavior: async function(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    const findEl = async (hash, x = 0) => {
      return (
        document.querySelector(hash) ||
        new Promise(resolve => {
          if (x > 50) {
            return resolve(document.querySelector("#app"));
          }
          setTimeout(() => {
            resolve(findEl(hash, ++x || 1));
          }, 100);
        })
      );
    };

    if (to.hash) {
      router.push({ path: to.path });
      let el = await findEl(to.hash);
      if ("scrollBehavior" in document.documentElement.style) {
        return window.scrollTo({ top: el.offsetTop, behavior: "smooth" });
      } else {
        return window.scrollTo(0, el.offsetTop);
      }
    }

    return { x: 0, y: 0 };
  },
  mode: 'history',
});
/*const router = new VueRouter({
  routes
});*/


router.beforeResolve(async (to, from, next) => {
  const sign = await awsAuth.signCheck();
  if (to.name === 'Login' && sign) {
    next({ name: 'Main' });
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    awsAuth
      .authenticated()
      .catch(() => {
        next({
          path: '/landing'
        });
      });
  }
  
  if (to.matched.some(record => record.meta.admin)) {
    if (!awsAuth.isAdmin()) { 
      next({ path: '/' });
    }
  }

  if (to.matched.some(record => record.meta.checkBrowser)) {
    console.log(navigator.userAgent);
    console.log('Includes Chrome: ', navigator.userAgent.includes('Chrome'));
    console.log('Includes Edge: ', navigator.userAgent.includes('Edg'));

    if (!navigator.userAgent.includes('Chrome') && !navigator.userAgent.includes('Edg') && !navigator.userAgent.includes('Safari')) {
      next({ path: '/browser-notice' })
    }
  }

  if (to.matched.some(record => record.meta.checkDevice)) {
    let isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    if (isMobile) {
      next({ path: '/browser-notice' })
    }
  }

  next();
});

new Vue({
  store,
  render: h => h(App),
  vuetify,
  router
}).$mount('#app');
