const state = {
  transparentHeader: false,
  hideUI: false
};

export const SET_HEADER_TRANSPARENT = 'SET_HEADER_TRANSPARENT';
export const SET_HIDE_UI = 'SET_HIDE_UI';

const mutations = {
  [SET_HEADER_TRANSPARENT](state, value) {
    state.transparentHeader = value;
  },
  [SET_HIDE_UI](state, value) {
    state.hideUI = value;
  }
};

export default { namespaced: true, state, mutations };
