<template>
<svg width="24" height="38" viewBox="0 0 24 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12 0.988159C5.40086 0.988159 0 6.25053 0 12.6805V24.3728C0 30.8027 5.40086 36.0651 12 36.0651C18.5991 36.0651 24 30.8027 24 24.3728V12.6805C24 6.25053 18.5991 0.988159 12 0.988159ZM22.2857 24.3728C22.2857 29.8844 17.6567 34.3948 12 34.3948C6.34329 34.3948 1.71429 29.8844 1.71429 24.3728V12.6805C1.71429 7.1688 6.34329 2.65849 12 2.65849C17.6567 2.65849 22.2857 7.1688 22.2857 12.6805V24.3728Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.9997 8.50464C11.5276 8.50464 11.1426 8.87981 11.1426 9.3398V13.5156C11.1426 13.9756 11.5276 14.3508 11.9997 14.3508C12.4718 14.3508 12.8569 13.9756 12.8569 13.5156V9.3398C12.8569 8.87979 12.4718 8.50464 11.9997 8.50464Z" fill="white"/>
</svg>

</template>
<script>
export default {

}
</script>