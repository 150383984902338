<template>
<svg width="30" height="22" viewBox="0 0 30 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="30" height="2" fill="#D8D8D8"/>
<rect y="10" width="30" height="2" fill="#D8D8D8"/>
<rect y="20" width="30" height="2" fill="#D8D8D8"/>
</svg>

</template>
<script>
export default {

}
</script>