<template>
<svg
   xmlns:dc="http://purl.org/dc/elements/1.1/"
   xmlns:cc="http://creativecommons.org/ns#"
   xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
   xmlns:svg="http://www.w3.org/2000/svg"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   height="13.20096"
   viewBox="0 0 26.573056 13.20096"
   width="26.573055"
   version="1.1"
   id="svg12"
   sodipodi:docname="email-icon.svg"
   inkscape:version="0.92.4 (5da689c313, 2019-01-14)">
  <metadata
     id="metadata18">
    <rdf:RDF>
      <cc:Work
         rdf:about="">
        <dc:format>image/svg+xml</dc:format>
        <dc:type
           rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
        <dc:title></dc:title>
      </cc:Work>
    </rdf:RDF>
  </metadata>
  <defs
     id="defs16" />
  <sodipodi:namedview
     pagecolor="#ffffff"
     bordercolor="#666666"
     borderopacity="1"
     objecttolerance="10"
     gridtolerance="10"
     guidetolerance="10"
     inkscape:pageopacity="0"
     inkscape:pageshadow="2"
     inkscape:window-width="2400"
     inkscape:window-height="1271"
     id="namedview14"
     showgrid="false"
     fit-margin-top="0"
     fit-margin-left="0"
     fit-margin-right="0"
     fit-margin-bottom="0"
     inkscape:zoom="9.933167"
     inkscape:cx="-10.950545"
     inkscape:cy="6.5449806"
     inkscape:window-x="-9"
     inkscape:window-y="-9"
     inkscape:window-maximized="1"
     inkscape:current-layer="svg12" />
  <g
     id="g10"
     style="fill:#ffffff"
     transform="matrix(4.9209361,0,0,4.2583743,-5.4130297,-11.497611)">
    <path
       d="m 1.4,5.8 c 0,0 -0.1,0 0,0 L 2.5,4.2 2.6,4.1 2.8,4.2 3.8,5 V 5 L 4.8,4.2 5,4.1 5.1,4.2 6.3,5.8 c 0,0 -0.1,0 -0.1,0 H 3.8 v 0 z"
       id="path2"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       d="m 1.2,2.7 c 0,0 0.1,0 0.2,0 h 3.1 1.6 c 0.1,0 0.1,0 0.2,0.1 L 5.1,3.6 4.2,4.3 3.8,4.6 3.3,4.3 Z"
       id="path4"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       d="m 6.5,3 v 2.5 c 0,0 0,0.1 0,0.1 L 5.2,3.9 6.5,3 c 0,0 0,0 0,0 z"
       id="path6"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
    <path
       d="m 2.3,3.9 -0.2,0.4 -1,1.4 c 0,0 0,-0.1 0,-0.1 V 4.3 3 c 0,0 0,0 0,0 z"
       id="path8"
       inkscape:connector-curvature="0"
       style="fill:#ffffff" />
  </g>
</svg>



</template>
<script>
export default {

}
</script>