<template>
<svg width="42" height="28" viewBox="0 0 42 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M37.4088 27.5331H4.5912C2.05649 27.5313 0.00178143 25.44 0 22.8597V5.34613C0.00173957 2.76604 2.05631 0.674543 4.5912 0.672729H37.4088C39.9435 0.674502 41.9982 2.76585 42 5.34613V22.8597C41.9983 25.4398 39.9437 27.5313 37.4088 27.5331ZM4.5912 2.56086C3.08117 2.56263 1.85643 3.80929 1.8547 5.34636V22.8599C1.85644 24.397 3.08117 25.6437 4.5912 25.6454H37.4088C38.9188 25.6437 40.1436 24.397 40.1453 22.8599V5.34636C40.1436 3.80929 38.9188 2.56263 37.4088 2.56086H4.5912Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.0768 20.5694H11.9232C11.4123 20.5694 11 20.1233 11 19.5745C11 19.0239 11.4123 18.5797 11.9232 18.5797H30.0768C30.586 18.5797 31 19.0239 31 19.5745C31 20.1233 30.586 20.5694 30.0768 20.5694Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.97259 20.5694H7.02741C6.45984 20.5694 6 20.1233 6 19.5745C6 19.0239 6.45984 18.5797 7.02741 18.5797H8.97259C9.54015 18.5797 10 19.0239 10 19.5745C10 20.1233 9.54016 20.5694 8.97259 20.5694Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M8.97259 9.62625H7.02741C6.45984 9.62625 6 9.18058 6 8.63049C6 8.08228 6.45984 7.6366 7.02741 7.6366H8.97259C9.54015 7.6366 10 8.08227 10 8.63049C10 9.18058 9.54016 9.62625 8.97259 9.62625Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M13.974 9.62625H12.026C11.4601 9.62625 11 9.18058 11 8.63049C11 8.08228 11.4601 7.6366 12.026 7.6366H13.974C14.5399 7.6366 15 8.08227 15 8.63049C15 9.18058 14.5399 9.62625 13.974 9.62625Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.9726 9.62625H18.0274C17.4598 9.62625 17 9.18058 17 8.63049C17 8.08228 17.4598 7.6366 18.0274 7.6366H19.9726C20.5402 7.6366 21 8.08227 21 8.63049C21 9.18058 20.5402 9.62625 19.9726 9.62625Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M25.974 9.62625H24.026C23.4601 9.62625 23 9.18058 23 8.63049C23 8.08228 23.4601 7.6366 24.026 7.6366H25.974C26.5399 7.6366 27 8.08227 27 8.63049C27 9.18058 26.5399 9.62625 25.974 9.62625Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M17.9726 15.5951H16.0274C15.4598 15.5951 15 15.1509 15 14.6003C15 14.0497 15.4598 13.6055 16.0274 13.6055H17.9726C18.5402 13.6055 19 14.0497 19 14.6003C19 15.1509 18.5402 15.5951 17.9726 15.5951Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.974 15.5951H21.026C20.4601 15.5951 20 15.1509 20 14.6003C20 14.0497 20.4601 13.6055 21.026 13.6055H22.974C23.5399 13.6055 24 14.0497 24 14.6003C24 15.1509 23.5399 15.5951 22.974 15.5951Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9726 15.5951H26.0274C25.4598 15.5951 25 15.1509 25 14.6003C25 14.0497 25.4598 13.6055 26.0274 13.6055H27.9726C28.5402 13.6055 29 14.0497 29 14.6003C29 15.1509 28.5402 15.5951 27.9726 15.5951Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M30.9721 9.62625H29.026C28.4581 9.62625 28 9.18058 28 8.63049C28 8.08228 28.4581 7.6366 29.026 7.6366H30.9721C31.5399 7.6366 32 8.08227 32 8.63049C32 9.18058 31.5399 9.62625 30.9721 9.62625Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M35.9721 20.5694H34.026C33.4581 20.5694 33 20.1233 33 19.5745C33 19.0239 33.4581 18.5797 34.026 18.5797H35.9721C36.5399 18.5797 37 19.0239 37 19.5745C37 20.1233 36.5399 20.5694 35.9721 20.5694Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M36.0566 15.5951H30.9414C30.4222 15.5951 30 15.1607 30 14.6222C30 14.0838 30.4222 13.6493 30.9414 13.6493H35.1153V7.61464C35.1153 7.07617 35.5375 6.64172 36.0568 6.64172C36.5778 6.64172 37 7.07617 37 7.61464V14.6222C37 14.8796 36.9011 15.1278 36.7227 15.3104C36.5461 15.493 36.3076 15.5952 36.0568 15.5952L36.0566 15.5951Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.8578 15.5951H7.14438C6.5122 15.5951 6 15.1509 6 14.6003C6 14.0497 6.51219 13.6055 7.14438 13.6055H12.8578C13.4878 13.6055 14 14.0497 14 14.6003C14 15.1509 13.4878 15.5951 12.8578 15.5951Z" fill="white"/>
</svg>

</template>
<script>
export default {

}
</script>