<template>
<svg width="32" height="24" viewBox="0 0 32 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.47828C14.2419 7.47828 12.6563 8.53807 11.9837 10.1617C11.3098 11.7867 11.682 13.6577 12.9252 14.9008C14.1684 16.144 16.0394 16.5163 17.6644 15.8424C19.288 15.1698 20.3478 13.5842 20.3478 11.826C20.3478 9.42524 18.4007 7.47821 15.9999 7.47821L16 7.47828ZM16 13.2758C15.4144 13.2758 14.8859 12.9226 14.6617 12.3805C14.4361 11.8397 14.5611 11.2161 14.9755 10.8017C15.3899 10.3873 16.0136 10.2622 16.5544 10.4878C17.0965 10.712 17.4497 11.2405 17.4497 11.8261C17.4497 12.2106 17.2962 12.5789 17.0244 12.8506C16.7527 13.1223 16.3845 13.2759 16 13.2759L16 13.2758Z" fill="#FF3C11"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.8696 4.65216C10.3044 4.08966 9.39131 4.08966 8.82609 4.65216C6.9239 6.55436 5.85461 9.13599 5.85461 11.8261C5.85461 14.5162 6.9239 17.0977 8.82609 19C9.09919 19.2717 9.47013 19.4225 9.85461 19.4198C10.2351 19.4185 10.5992 19.2676 10.8696 19C11.4321 18.4348 11.4321 17.5217 10.8696 16.9565C9.50679 15.5964 8.74049 13.7514 8.74049 11.8261C8.74049 9.90079 9.50679 8.05564 10.8696 6.69564C11.4321 6.13042 11.4321 5.21738 10.8696 4.65216Z" fill="#FF3C11"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.2459 4.65216C22.6807 4.08966 21.7676 4.08966 21.2024 4.65216C20.6413 5.21738 20.6413 6.13042 21.2024 6.69564C22.5652 8.05571 23.3315 9.90079 23.3315 11.8261C23.3315 13.7514 22.5652 15.5965 21.2024 16.9565C20.6413 17.5217 20.6413 18.4348 21.2024 19C21.4728 19.2677 21.8369 19.4185 22.2174 19.4198C22.5924 19.4416 22.9619 19.3179 23.2459 19.072C25.1494 17.1698 26.2187 14.5896 26.2187 11.8981C26.2187 9.20785 25.1494 6.62782 23.2459 4.72416V4.65216Z" fill="#FF3C11"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.7826 2.60871C7.35053 2.04078 7.35053 1.1196 6.7826 0.550276C6.21467 -0.017654 5.29348 -0.017654 4.72416 0.550276C1.73366 3.54078 0.0528564 7.59654 0.0528564 11.8261C0.0528564 16.0557 1.73355 20.1113 4.72416 23.1019C4.99862 23.3723 5.3682 23.5245 5.75404 23.5218C6.13856 23.5245 6.50948 23.3723 6.78256 23.1019C7.05702 22.8288 7.21192 22.4592 7.21192 22.0721C7.21192 21.6862 7.05703 21.3153 6.78256 21.0435C4.33964 18.5979 2.96726 15.2828 2.96726 11.8261C2.96726 8.36944 4.33954 5.05431 6.78256 2.60875L6.7826 2.60871Z" fill="#FF3C11"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.2758 0.550276C26.7065 -0.017654 25.7853 -0.017654 25.2174 0.550276C24.6495 1.11956 24.6495 2.04078 25.2174 2.60871C27.6603 5.05435 29.0327 8.36941 29.0327 11.8261C29.0327 15.2828 27.6604 18.5979 25.2174 21.0435C24.9429 21.3152 24.788 21.6862 24.788 22.072C24.788 22.4593 24.9429 22.8288 25.2174 23.1019C25.4905 23.3723 25.8614 23.5244 26.2459 23.5217C26.6318 23.5244 27.0013 23.3723 27.2758 23.1019C30.2663 20.1114 31.9471 16.0556 31.9471 11.8261C31.9471 7.5965 30.2664 3.54085 27.2758 0.550242L27.2758 0.550276Z" fill="#FF3C11"/>
</svg>

</template>
<script>
export default {

}
</script>