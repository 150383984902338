import '@fortawesome/fontawesome-free/css/all.css';

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
import IconAudio from '@/components/icons/IconAudio.vue'
import IconCamera from '@/components/icons/IconCamera.vue'
import IconPeople from '@/components/icons/IconPeople.vue'
import IconMap from '@/components/icons/IconMap.vue'
import IconFull from '@/components/icons/IconFull.vue'
import IconMouse from '@/components/icons/IconMouse.vue'
import IconKeyboard from '@/components/icons/IconKeyboard.vue'
import IconMic from '@/components/icons/IconMic.vue'
import IconMicOff from '@/components/icons/IconMicOff.vue'
import IconMuteMic from '@/components/icons/IconMuteMic.vue'
import IconLogo from '@/components/icons/IconLogo.vue'
import IconCaretLeft from '@/components/icons/IconCaretLeft.vue'
import IconCaretRight from '@/components/icons/IconCaretRight.vue'
import IconLock from '@/components/icons/IconLock.vue'
import IconUser from '@/components/icons/IconUser.vue'
import IconEmail from '@/components/icons/IconEmail.vue'
import IconRePassword from '@/components/icons/IconRePassword.vue'
import IconHamburger from '@/components/icons/IconHamburger.vue'
import IconLive from '@/components/icons/IconLive.vue'
import IconQr from '@/components/icons/IconQr.vue'


export default new Vuetify({
  icons: {
    iconfont: 'fa',
    values: {
        audioIcon: {
          component: IconAudio
        },
        cameraIcon: {
          component: IconCamera
        },
        peopleIcon: {
          component: IconPeople
        },
        mapIcon: {
          component: IconMap
        },
        fullIcon: {
          component: IconFull
        },
        mouseIcon: {
          component: IconMouse
        },
        keyboardIcon: {
          component: IconKeyboard
        },
        micIcon: {
          component: IconMic
        },
        micIconOff: {
          component: IconMicOff
        },
        micMuteIcon: {
          component: IconMuteMic
        },
        logoIcon: {
          component: IconLogo
        },
        leftIcon: {
          component: IconCaretLeft
        },
        rightIcon: {
          component: IconCaretRight
        },
        lockIcon: {
          component: IconLock
        },
        userIcon: {
          component: IconUser
        },
        emailIcon: {
          component: IconEmail
        },
        rePasswordIcon: {
          component: IconRePassword
        },
        hamburgerIcon: {
          component: IconHamburger
        },
        liveIcon: {
          component: IconLive
        },
        qrIcon: {
          component: IconQr
        },
    },


  },
  theme: { dark: true },    
});