<template>
  <v-app>
    <v-expand-transition>
      <page-wrapper>
        <transition name="component-fade" mode="out-in" appear>
          <router-view />
        </transition>
      </page-wrapper>
      <!-- <navigation-bar></navigation-bar>     -->
    </v-expand-transition>
  </v-app>
</template>

<script>
import PageWrapper from './components/layout/PageWrapper';

export default {
  name: 'App',
  components: {
    PageWrapper
  },
  data() {
    return {

    }
  },
  mounted() {
    this.$store.dispatch('auth/recover');
  }
}
</script>

<style lang="scss">
*{
  font-family:Oswald;
}

.title, .v-application .title, #app .display-2{
  font-family:Oswald!important;
}
h1.page-title{

  width: 100%;
  text-align: center;
  padding: 2em 0 1em 0;
  text-transform: uppercase;
  letter-spacing: 4.6px;
  font-size: 50px;

}

.menu-template {
  position: relative;
  padding: 15px 10px;
  border-bottom: 3px solid #ccc;
  z-index: 100;

  &:last-child {
    border: none;

    &:after, &:before {
      content: none;
    }
  }

  &:before {
    z-index: 50;
    content: "";
    position: absolute;
    left: 0;
    bottom: -6px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 5px solid #ccc;
    border-bottom: 5px solid transparent;
  }

  &:after {
    z-index: 50;
    content: "";
    position: absolute;
    right: 0;
    bottom: -6px;
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 5px solid #ccc;
    border-bottom: 5px solid transparent;
  }
}
.nav {
  display: flex;
}
.search-list {
  box-shadow: none;
}
.nav p {
  padding: 0px 30px 0px 0px;
  font-size: 18px;
  color: #000;
}
.nav p:hover {
  opacity: .7;
}
.nav p a {
  text-decoration: none;
}
.sign-out {
  width: 160px;
  margin: 0 auto;
}
html #app .navigation .v-btn--active span {font-weight: 600;}
.component-fade-enter-active, .component-fade-leave-active {
  transition: opacity .2s ease;
}
.component-fade-enter, .component-fade-leave-to {
  opacity: 0;
}

.transparent-bg{
  background:transparent!important;
  box-shadow:unset!important;
}
input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
:-internal-autofill-selected,
:-internal-autofill-selected:hover,
:-internal-autofill-selected:focus,
:-webkit-autofill,
:-webkit-autofill:hover,
:-webkit-autofill:focus
{
  appearance:unset;
  -webkit-appearance: none;
  -moz-appearance:none;
  -ms-appearance:none;
  -o-appearance:none;

  background: transparent!important;
}



.v-label
{
  top: 50%!important;
  transform: translateY(-50%);
}

.v-text-field .v-input__prepend-inner{
  align-self: center;
  margin-top: 0!important;
}

.v-icon__component {
  height: 20px;
  width: 20px;
}


.v-text-field--outlined legend{
  display:none
}

.v-input--is-focused label,
.v-input--is-dirty label
{
  display:none
}

.v-card__actions ,  .v-card__actions > .v-btn.v-btn {
  padding: 8px 0!important;
}
.v-card__subtitle, .v-card__text,  .v-card__title {
  padding: 8px 0!important;
}

img{
  max-width:100%!important;
}
.margin-right--2{
  margin-right:2em!important;
}

span.title--underline {
  font-weight: 100;
  display: inline-block;
  // border-bottom: 1px solid;
  padding-bottom: 9px;
}

.v-application div.title{
  font-size: 2.5em!important;
  line-height: 1;
}

.border-radius--10{
  border-radius:10px;
}
article{
  .about .title{
    font-size:2em!important;
  }
}

.info-section header{
  font-size:2em!important;
}

.v-application .black{
  background:transparent!important;
}


.pod-menu  button.v-btn.v-btn--icon:focus svg.v-icon__component * {
  fill: #FF3C11!important;
}

header .wrapper{
  background:transparent;
}
.route-auth{
  .v-main__wrap{
    display:flex;
  }
}

.theme--dark.v-btn:focus::before {
  opacity: 0!important;
}
.navigation .theme--dark.v-btn.v-btn--has-bg {
  background:transparent;
  &:before{
    content:none;
    box-shadow: none;
  }
  &.v-btn--is-elevated{
    box-shadow: none;
  }
}
.footer-tickets{
  display:none;
}
.mobile-menu-list{
  .v-btn__content{
    justify-content: flex-start;
  }
}
.v-btn__content {
      letter-spacing: 2px;
      text-transform: uppercase;

      font-weight: 300;
      color: #fff;
    }
html {
  overflow-x: hidden;
  max-width: 100%;
  #app {
    .v-text-field--outlined .v-input__slot {
      padding: 0 21px;

      & > .v-input__prepend-inner {
        padding-right: 14px;
      }

      & > fieldset {
        top: 0;
        background: rgba(255, 255, 255, 0.0795096);
        border: 1px solid #FFFFFF;
      }

      & > .v-text-field__slot > * {
        font-size: 18px;
        letter-spacing: 2px;
        color: rgb(255, 255, 255);
        font-weight: 300;
      }
    }



    .v-btn--outlined .v-btn__content {
      font-weight: 500;
    }

    .btn-logo,
    .btn-logo:hover {
      background: none;
      border: none;
      box-shadow: none;
      margin: 0;
      padding: 0;
      height: auto;
      width: auto;
      color: inherit;
      font-size: inherit;
      display: block;
      line-height: inherit;

      &:before,
      & > span.v-ripple__container {
        display: none;
      }
    }
  }
}

.line-separator{
    width: 70px;
    margin: auto;
    margin-top: 15px;
}



@media screen and(min-width:758px) {
  hr.under-title {
    max-width: 150px;
  }
  hr.under-title-header {
    max-width: 150px;
    margin:auto;
  }

}
@media screen and(max-width:757px) {

  .option-label{
    text-align:center;
  }
  .show-info{
    text-align:center;
  }
  .info-section-actions{
    flex-direction: column;
    a{
      width: 100%;
      margin-right: unset!important;
      margin: auto;
      margin-bottom:1em;
    }
  }
  .v-application  .title{
    text-align: center;
  }
  main .subtitle{
    text-align:center!important;
  }
  hr.under-title{
    max-width: 50%;
    margin: auto;
  }
  hr.under-title-header{
    max-width: 150px;
    margin: auto;
  }
  main .description{
    text-align: center!important;
  }
}
</style>
